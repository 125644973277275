import React, { useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  enableAutoFunding,
  getAllTransactions,
} from "~/redux/actions/stripe-actions.js";
import { getUserData } from "~/redux/actions/auth-actions.js";
import { success } from "~/redux/actions/snackbar-actions.js";

// utils
import { MIN_FUNDING_LIMIT } from "~/utils/constants.js";
import { MESSAGES } from "~/utils/message.js";
import { numberWithComma } from "~/utils/helperFunctions.js";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Box,
  Input,
  InputLabel,
  CircularProgress,
} from "@mui/material";

// Icons
import { ModalCrossCIcon } from "~/assets/images";

// Styles
import "../AddFunds/styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EnableFunding = (props) => {
  const { handleClose, open } = props;
  const user = useSelector((state) => state.userReducers?.user);
  const [autoFunds, setAutoFunds] = useState(user?.organization?.minAutoFundingAmount || 50);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [autoFundError, setAutoFundError] = useState("");
  const [respError, setRespError] = useState("");
  const dispatch = useDispatch();

  const validateFunding = (value, setErrorCallback) => {
    if (isNaN(value) || value < MIN_FUNDING_LIMIT) {
      setErrorCallback(MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_FUNDING_VALIDATION);
      return false;
    } else {
      setErrorCallback("");
      return true;
    }
  };

  const handleEnableFunding = async () => {
    const isAutoFundValid = validateFunding(parseFloat(autoFunds.toString().replaceAll(",", "")), setAutoFundError);

    if (!isAutoFundValid) return;
    setLoading((prev) => !prev);
    const data = await enableAutoFunding(
      parseFloat(autoFunds.toString().replaceAll(",", ""))
    );
    if (data.status === 200) {
      handleClose();
      dispatch(getUserData());
      dispatch(
        success(
          MESSAGES.ACCOUNT.WALLET.STRIPE.UPDATE_AUTO_FUNDING_SUCCESS,
          MESSAGES.ACCOUNT.WALLET.STRIPE.FUNDS_ADDED
        )
      );
      dispatch(getAllTransactions(1, 10));
    } else {
      setRespError(data.data.message);
    }
    setLoading((prev) => !prev);
  };

  const handleChangeInput = (value) => {
    if (value.length > 8) return;
    if (typeof value == "string") {
      let valueToAsign = value.replaceAll("$", "").replaceAll(",", "");
      if (valueToAsign !== "" && !/^-?\d*\.?\d{0,2}$/.test(valueToAsign))
        return;
      valueToAsign = numberWithComma(valueToAsign);
      setAutoFunds(valueToAsign);
    } else {
      value = numberWithComma(value);
      setAutoFunds(value);
    }
    if (error && value >= 50) {
      setError("");
    }
    if (autoFundError && value >= 50) {
      setAutoFundError("");
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="fundsModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="fundsModalContent">
          <Box className="fundsHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.ACCOUNT.WALLET.STRIPE.UPDATE_AUTOMATIC_FUNDING}
            </Typography>
          </Box>
          <Box className="amountInputWrapper">
              <Box>
                <InputLabel className="fundsLabel">
                  {MESSAGES.ACCOUNT.WALLET.STRIPE.ENTER_MIN_AUTO_AMOUNT}:
                </InputLabel>
                <Input
                  className="fundsInputField"
                  type="text"
                  placeholder="$10,000.00"
                  value={autoFunds ? `$${autoFunds}` : autoFunds}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, false, true)
                  }
                />
                {autoFundError && (
                  <Typography className="errorMessage">
                    {autoFundError}
                  </Typography>
                )}
              </Box>
          </Box>
          <Box className="fundsModalBtns">
            <Button onClick={handleClose}>
              {MESSAGES.ACCOUNT.WALLET.STRIPE.CANCEL}
            </Button>
            <Button disabled={loading} 
            onClick={handleEnableFunding}
            sx={{maxWidth: "160px !important"}}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                MESSAGES.ACCOUNT.WALLET.STRIPE.UPDATE_AUTO_FUNDING
              )}
            </Button>
          </Box>
          {respError && (
            <Typography className="fundsModalContent__error">
              {respError}{" "}
            </Typography>
          )}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default EnableFunding;
