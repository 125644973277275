import React from "react";

//utils
import { percentager } from "~/utils/helperFunctions";
import { giveDaysUptilNow } from "~/utils/date-format";
import { MESSAGES } from "~/utils/message";

// MUI Components
import { Box } from "@mui/material";

// Icons
import {
  MailedCIcon,
  TransitOrderCIcon,
  DeliveredOrderCIcon,
  RouteCIcon,
  SenderCIcon,
  ScannedCIcon,
  NoScannedCIcon,
  CalenderCIcon,
} from "~/assets/images";

// Styles
import "./styles.scss";


function DeliveryInfo({info, overview, stepper}) {
  
  const deliverableContacts =  overview?.deliverableContacts || 0;
  const totalContacts = overview?.totalContacts || 0;

  return (
    <Box className="delivery_info">
      <div className="card_outer">
        <div className="card">
          <img src={MailedCIcon} alt="icon" />
          <p className="amount">{info.orderItemStatuses?.totalMailed || 0}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.MAILED}</span>
          <p className="percentage">{percentager(info.orderItemStatuses?.totalMailed || 0, deliverableContacts)}%</p>
        </div>
        <div className="card">
          <img src={TransitOrderCIcon} alt="icon" />
          <p className="amount">{info?.orderItemStatuses?.['In Transit']}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.IN_TRANSIT}</span>
          <p className="percentage">{percentager(info?.orderItemStatuses?.['In Transit'], deliverableContacts )}%</p>
        </div>
        <div className="card">
          <img src={DeliveredOrderCIcon} alt="icon" />
          <p className="amount">{info?.orderItemStatuses?.['Delivered']}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.DELIVERED}</span>
          <p className="percentage">{percentager(info?.orderItemStatuses?.['Delivered'], deliverableContacts)}%</p>
        </div>
        <div className="card">
          <img src={RouteCIcon} alt="icon" />
          <p className="amount">{info?.orderItemStatuses?.['Re-Routed']}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.RE_ROUTE}</span>
          <p className="percentage">{percentager(info?.orderItemStatuses?.['Re-Routed'],  deliverableContacts )}%</p>
        </div>
        <div className="card">
          <img className="senderImg" src={SenderCIcon} alt="icon" />
          <p className="amount">{info?.orderItemStatuses?.['Returned to Sender']}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.RETURNED_TO_SENDER}</span>
          <p className="percentage">{percentager(info?.orderItemStatuses?.['Returned to Sender'],  deliverableContacts)}%</p>
        </div>
      </div>
      <div  className="card_outer">
        <div className="card">
          <img src={MailedCIcon} alt="icon" />
          <p className="amount">{info?.orderItemStatuses?.totalMailed || 0}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.MAILED}</span>
          <p className="percentage">{percentager(info?.orderItemStatuses?.totalMailed || 0, deliverableContacts)}%</p>
        </div>
        <div className="card">
          <img src={ScannedCIcon} alt="icon" />
          <p className="amount">{info?.geoChart?.scannedOrderItems}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.SCANNED}</span>
          <p className="percentage">{percentager(info?.geoChart?.scannedOrderItems, totalContacts)}%</p>
        </div>
        <div className="card">
          <img src={NoScannedCIcon} alt="icon" />
          <p className="amount">{info?.geoChart?.notScannedOrderItems}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.NOT_SCANNED}</span>
          <p className="percentage">{percentager(info?.geoChart?.notScannedOrderItems, totalContacts)}%</p>
        </div>
        <div className="card">
          <img src={CalenderCIcon} alt="icon" />
          <p className="amount">{giveDaysUptilNow(stepper?.createdAt)}</p>
          <span className="txt">{MESSAGES.ORDERS.DETAIL.OVERVIEW.DAY_ELAPSED}</span>
          <p className="percentage"></p>
        </div>
      </div>
    </Box>
  );
}

export default DeliveryInfo;
