import request from '~/utils/request';
import {
  GET_ALL_TRANSACTIONS,
  SET_STRIPE_PAYMENT_METHODS,
  SET_TRANSACTIONS_LOADER,
  SET_METHOD_LOADER,
} from './action-types';

const getAllPaymentMethods = () => async (dispatch) => {
  try {
    const data = await request.get('/stripe/payment-method/get');
    dispatch({
      type: SET_STRIPE_PAYMENT_METHODS,
      payload: { data: data.data.data },
    });
    dispatch({ type: SET_METHOD_LOADER, payload: false });
  } catch (error) {
    return error.response;
  }
};

const getClientSecret = () => {
  return request.post('stripe/setup/intent/create', {});
};

const addPaymentMethod = async (paymentMethod) => {
  return request.post('stripe/payment-method/attach', { paymentMethod });
};

const detachPaymentMethod = async (paymentMethodId) => {
  try {
    const data = await request.post('/stripe/payment-method/detach', {
      paymentMethodId,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

const changeDefaultPaymentMethod = async (paymentMethodId) => {
  try {
    const data = await request.post('/stripe/payment-method/set-default', {
      paymentMethodId,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

const addFunds = async (amount, isAutoFundingEnabled, minAutoFundingAmount = 50) => {
  try {
    const data = await request.post('/stripe/wallet/funds/add', {
      amount,
      isAutoFundingEnabled,
      minAutoFundingAmount: isAutoFundingEnabled ? minAutoFundingAmount : 50
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

const getAllTransactions =
  (page = 1, pageSize = 10) =>
    async (dispatch) => {
      try {
        dispatch({ type: SET_TRANSACTIONS_LOADER, payload: true })
        const data = await request.get(`/stripe/transactions/get`, {
          page,
          pageSize,
        });
        dispatch({ type: SET_TRANSACTIONS_LOADER, payload: false })
        const combinedData = data.data.data;
        const combinedRows = combinedData.rows.map((transactions) => ({
          ...transactions,
          checked: false,
        }));
        combinedData.rows = combinedRows
        dispatch({
          type: GET_ALL_TRANSACTIONS,
          payload: { data: combinedData },
        });
      } catch (error) {
        // return error.response;
        dispatch({ type: SET_TRANSACTIONS_LOADER, payload: false })
      }
    };

const disableAutoFunding = async () => {
  try {
    const data = await request.post(
      '/stripe/wallet/funds/disable-auto-funding',
      {},
    );
    return data;
  } catch (error) {
    return error.response;
  }
};

const enableAutoFunding = async (minAutoFundingAmount) => {
  try {
    const data = await request.post(
      '/stripe/wallet/funds/enable-auto-funding',
      {
        minAutoFundingAmount: minAutoFundingAmount
      },
    );
    return data;
  } catch (error) {
    return error.response;
  }
};

const upgradePlanCost = async (payload) => {
  try {
    const data = await request.post(
      'stripe/subscriptions/preview-upgrade-cost',
      payload,
    );
    return data;
  } catch (error) {
    return error.response;
  }
};


const upgradePlan = async (payload) => {
  try {
    const data = await request.post(
      '/stripe/subscriptions/upgrade-request',
      payload,
    );
    return data;
  } catch (error) {
    return error.response;
  }
};

const downgradePlanCost = async (payload) => {
  try {
    const data = await request.post(
      '/stripe/subscriptions/is-downgradeable',
      payload,
    );
    return data;
  } catch (error) {
    return error.response;
  }
};


const downgradePlan = async (payload) => {
  try {
    const data = await request.post(
      'stripe/subscriptions/downgrade-request',
      payload,
    );
    return data;
  } catch (error) {
    return error.response;
  }
};

const transBulkActions = async (payload) => {
  try {
    const data = await request.post(
      'transactions/bulk-actions',
      payload,
    );
    return data;
  } catch (error) {
    return error.response;
  }
}



export {
  addPaymentMethod,
  getAllPaymentMethods,
  getClientSecret,
  detachPaymentMethod,
  changeDefaultPaymentMethod,
  addFunds,
  getAllTransactions,
  disableAutoFunding,
  enableAutoFunding,
  upgradePlanCost,
  upgradePlan,
  downgradePlanCost,
  downgradePlan,
  transBulkActions
};
