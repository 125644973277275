import React, { useEffect, useState } from "react";

// Libraries/Packages
import { Routes, Route, Navigate } from "react-router-dom";

//Hooks
import { useDynamicPageTitle } from "~/hooks/pageTitleHook";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { getUserData } from "~/redux/actions/auth-actions";
import {
  fetchAllProducts,
  fetchProductTypes,
} from "~/redux/actions/products-actions";
import { fetchCustomFields } from "~/redux/actions/customFields-actions";
import { getAllPaymentMethods } from "~/redux/actions/stripe-actions";
import { fetchGeneralSetting } from "~/redux/actions/general-actions";

// Utils
import { removeItem } from "~/utils/local-storage";
import { getUserToken, getResetToken, getDashboardMode } from "~/utils/auth";

// Components
import SignUp from "~/components/CreateAccount";
import SignIn from "~/components/SignIn";
import ForgotPassword from "~/components/ForgotPassword";
import Verification from "~/components/Verification";
import UpdatePassword from "~/components/UpdatePassword";
import Layout from "~/components/Layout";
import Dashboard from "~/components/Dashboard";
import Contacts from "~/components/Contacts";
import Templates from "./components/Templates/Templates";
import ContactDetails from "~/components/Contacts/ContactDetails";
import ImportContacts from "~/components/Contacts/ImportContacts";
import Orders from "~/components/Orders";
import GlobalSnackbar from "~/components/Snackbar";
import Account from "~/components/Account/Profile";
import User from "~/components/Account/User";
import UnverifiedEmail from "~/components/UnverifiedEmail";
import Plan from "~/components/Plan";
import CustomFields from "~/components/Settings/CustomFields";
import CreateOrder from "~/components/Orders/CreateOrder";
import ApiKeys from "~/components/Settings/ApiKeys";
import Wallet from "~/components/Account/Wallet";
import CreateContacts from "~/components/Contacts/CreateContact";
import General from "~/components/Settings/General";
import Tags from "~/components/Settings/Tags";
import Webhooks from "~/components/Settings/Webhooks";
import WebhookDetails from "~/components/Settings/Webhooks/WebhookDetails";
import CreateOrEditWebhook from "~/components/Settings/Webhooks/CreateOrEditWebhook";
import WebhookLog from "~/components/Settings/Webhooks/WebhookLog";
import OrderOverview from "~/components/Orders/OrderDetailsInfo/Overview";
import OrderContacts from "~/components/Orders/OrderDetailsInfo/Contacts";
import TemplateBuilder from "~/components/TemplateBuilder";
import ModeReminder from "~/components/ModeReminder";
import OfferPercentage from "~/components/OfferPercentage";

// Admin Routes
import AdminDashboard from "~/components/AdminPortal/Dashboard";
import AdminOrders from "~/components/AdminPortal/Orders";
import AdminUsers from "~/components/AdminPortal/Users";
import AdminBatches from "~/components/AdminPortal/Batches";
import AdminCategories from "~/components/AdminPortal/Categories";
import AdminDesignerQueries from "~/components/AdminPortal/DesignerQueries";
import AdminDesignerQueriesTemplateBuilder from "~/components/AdminPortal/DesignerQueries/TemplateBuilder";
import useQueryParams from "./hooks/useQueryParams";

function App() {
  const [unVerifiedEmail, setUnVerifiedEmail] = useState(false);
  // chatbot widget
  const [isWidgetVisible, setIsWidgetVisible] = useState(false);

  const { allSearchParams } = useQueryParams();

  const dispatch = useDispatch();

  let isAuthenticated = getUserToken();

  let isResetPassword = getResetToken();

  // MiddleWare for protected routes
  function RequireClientAuth({ children, redirectTo }) {
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
  }

  const isVerified = useSelector((state) => state.userReducers.user.status);

  const isAdminMode = JSON.parse(getDashboardMode()) || false;

  const isSystemAdmin = useSelector(
    (state) => state.userReducers.isSystemAdmin
  );

  const currentPath = window?.location?.pathname;

  const getLayoutProps = () => {
    if (currentPath.includes("/account")) {
      return { isSideBar: true, name: "account", isAdminMode };
    } else if (currentPath.includes("/settings")) {
      return { isSideBar: true, name: "settings", isAdminMode };
    } else if (currentPath.includes("/orders/detail")) {
      return { isSideBar: true, name: "order", isAdminMode };
    } else {
      return { isSideBar: false, isAdminMode };
    }
  };

  // Dynamically Update page title
  useDynamicPageTitle();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserData());
      dispatch(fetchProductTypes());
      dispatch(fetchAllProducts());
      dispatch(
        fetchCustomFields({
          pageSize: 500,
        })
      );
      dispatch(fetchGeneralSetting());
      dispatch(getAllPaymentMethods());
      setUnVerifiedEmail(isVerified === "Pending" ? true : false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isSystemAdmin !== null && isSystemAdmin === false) {
      removeItem("isAdminMode");
    }
  }, [isAdminMode]);

  // Hubspot Chatbot display
  const handleChatOpen = () => {
    const chatbot = document.getElementById(
      "hubspot-messages-iframe-container"
    );
    if (chatbot) {
      chatbot?.style.setProperty("display", "initial", "important");
      chatbot?.style.setProperty(
        "box-shadow",
        "0px 0px 9px 2px #0000002e",
        "important"
      );
      chatbot?.style.setProperty("border-radius", "20px", "important");
      chatbot?.style.setProperty("background", "#00000024", "important");
    }
    setIsWidgetVisible(true);
    window?.HubSpotConversations?.widget?.load({ widgetOpen: false });
    window?.HubSpotConversations?.widget?.open();
  };

  useEffect(() => {
    const hideChatbot = () => {
      const chatbot = document.getElementById(
        "hubspot-messages-iframe-container"
      );
      if (chatbot) {
        chatbot?.style.setProperty("display", "none", "important");
        setIsWidgetVisible(false);
      } else {
        setTimeout(hideChatbot, 100);
      }
    };
    hideChatbot();

    const handleWidgetClosed = (e) => {
      setTimeout(() => {
        hideChatbot();
      }, 100);
    };

    window?.HubSpotConversations?.on("widgetClosed", handleWidgetClosed);

    return () => {
      window?.HubSpotConversations?.off("widgetClosed", handleWidgetClosed);
    };
  }, []);
  // Hubspot Chatbot display

  return (
    <>
      <Routes>
        {!isAuthenticated && (
          <>
            <Route path="/login" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/reset-password" element={<ForgotPassword />} />
          </>
        )}
        {isResetPassword && (
          <>
            <Route path="/verification" element={<Verification />} />
            <Route path="/update-password" element={<UpdatePassword />} />
          </>
        )}
        <Route
          path="/"
          element={
            <Navigate
              to={
                isAdminMode
                  ? `admin/overview${allSearchParams}`
                  : `overview${allSearchParams}`
              }
            />
          }
        ></Route>
        <Route
          element={
            isAuthenticated ? (
              <React.Fragment>
                <Layout
                  {...getLayoutProps()}
                  onClick={handleChatOpen}
                  isWidgetVisible={isWidgetVisible}
                  setIsWidgetVisible={setIsWidgetVisible}
                />
                <UnverifiedEmail
                  isOpen={unVerifiedEmail}
                  handleCloseModal={() => setUnVerifiedEmail(false)}
                />
              </React.Fragment>
            ) : (
              <Navigate to={`/login${allSearchParams}`} />
            )
          }
        >
          {/* Add Next Auth Route Here*/}
          {!isAdminMode && (
            <>
              <Route path="overview" element={<Dashboard />} />
              <Route path="contacts" element={<Contacts />}></Route>
              <Route path="contacts/:id" element={<ContactDetails />}></Route>
              <Route
                path="contacts/import"
                element={<ImportContacts />}
              ></Route>
              <Route
                path="contacts/create"
                element={<CreateContacts />}
              ></Route>
              <Route path="templates" element={<Templates />} />
              <Route path="create-template" element={<TemplateBuilder />} />
              <Route
                path="template-builder/:id?"
                element={<TemplateBuilder />}
              />
              <Route path="orders" element={<Orders />}></Route>
              <Route
                path="orders/create-order"
                element={<CreateOrder />}
              ></Route>
              {/* order detail rooutes */}
              <Route
                path="orders/detail/overview/:id"
                element={<OrderOverview />}
              ></Route>
              <Route
                path="orders/detail/contacts/:id"
                element={<OrderContacts />}
              ></Route>
              {/* Account Route*/}
              <Route
                path="/account"
                element={<Navigate to="/account/profile" />}
              ></Route>
              <Route path="account/profile" element={<Account />}></Route>
              <Route path="account/wallet" element={<Wallet />}></Route>
              <Route path="account/plan" element={<Plan />}></Route>
              <Route path="account/users" element={<User />}></Route>
              {/* Account Settngs*/}
              <Route
                path="/settings"
                element={<Navigate to="/settings/api-keys" />}
              ></Route>
              <Route path="settings/api-keys" element={<ApiKeys />}></Route>

              <Route path="account/wallet" element={<Wallet />}></Route>
              <Route path="account/plan" element={<Plan />}></Route>

              <Route path="account/users" element={<User />}></Route>

              {/* Account Settngs*/}
              <Route
                path="/settings"
                element={<Navigate to="/settings/general" />}
              ></Route>
              <Route path="settings/general" element={<General />}></Route>
              <Route
                path="settings/custom-fields"
                element={<CustomFields />}
              ></Route>
              <Route path="settings/api-keys" element={<ApiKeys />}></Route>
              <Route path="settings/tags" element={<Tags />}></Route>
              <Route path="settings/webhooks" element={<Webhooks />}></Route>
              <Route path="webhooks/:id" element={<WebhookDetails />}></Route>
              <Route
                path="webhooks/webhook-log/:id"
                element={<WebhookLog />}
              ></Route>
              <Route
                path="settings/webhooks/create"
                element={<CreateOrEditWebhook />}
              ></Route>
              <Route
                path="settings/webhooks/update/:id"
                element={<CreateOrEditWebhook />}
              ></Route>
              {/* offer percentage */}
              <Route
                path="property-offers"
                element={<OfferPercentage />}
              ></Route>
            </>
          )}

          {isAdminMode && (
            <>
              <Route path="admin/overview" element={<AdminDashboard />} />
              <Route path="admin/orders" element={<AdminOrders />} />
              <Route
                path="admin/orders/batch/:batchId"
                element={<AdminOrders />}
              />
              <Route path="admin/users/:userId?" element={<AdminUsers />} />
              <Route
                path="admin/batches/:batchId?"
                element={<AdminBatches />}
              />
              <Route path="admin/categories" element={<AdminCategories />} />
              <Route
                path="admin/designer-queries"
                element={<AdminDesignerQueries />}
              />
              <Route
                path="admin/designer-queries/template/:id"
                element={<AdminDesignerQueriesTemplateBuilder />}
              />
            </>
          )}
        </Route>
        <Route
          path="*"
          element={<Navigate to={`/${allSearchParams}`} />}
        ></Route>
      </Routes>

      {/* SNACKBAR FOR NOTIFICATIONS */}
      <GlobalSnackbar />
      {/* Mode Reminder */}
      {!isAdminMode && <ModeReminder />}
    </>
  );
}

export default App;
