import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";
import { numberWithComma } from "~/utils/helperFunctions";

// Components
import SelectContact from "~/components/Orders/CreateOrder/SelectContact";
import ImportContacts from "~/components/Contacts/ImportContacts";
import GenericAutocomplete from "~/components/General/Autocomplete";

// MUI Components
import { Box, Divider, Grid, Typography } from "@mui/material";

const AddContacts = (props) => {
  const {
    contactType,
    contactOptions,
    setContactType,
    setSelectedContact,
    selectedContact,
    contactCount,
    tagsOptions,
    setUploadProcess,
    cancelOrder,
    setSearch,
    setErrors,
    loading,
    errors,
    switchTab,
    activeItemId,
    setImportedErrorRecordCount,
  } = props;
  return (
    <>
      <Grid item lg={12}>
        <SelectContact value={contactType} setValue={setContactType} />
        <Divider className="orderDivider" />
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="templateSelectWrapper">
              {contactType === "Upload" ? (
                <ImportContacts
                  reusable={true}
                  setUploadProcess={setUploadProcess}
                  setSelectedContact={setSelectedContact}
                  selectedContact={selectedContact}
                  cancel={cancelOrder}
                  switchTab={switchTab}
                  activeItemId={activeItemId}
                  setImportedErrorRecordCount={setImportedErrorRecordCount}
                />
              ) : (
                <GenericAutocomplete
                  placeholder={
                    contactType === "Single"
                      ? "Search by name or company name"
                      : "Search by tag or Select all contacts"
                  }
                  options={
                    contactType === "Single"
                      ? contactOptions
                      : contactType === "Multiple"
                      ? tagsOptions
                      : []
                  }
                  setSelectedValue={setSelectedContact}
                  selectedValue={
                    typeof selectedContact === "string" ? null : selectedContact
                  }
                  search={setSearch}
                  updateErrors={setErrors}
                  name={"contacts"}
                  loading={loading}
                />
              )}
              {contactCount !== null &&
                contactCount >= 0 &&
                selectedContact instanceof Object &&
                contactType === "Multiple" && (
                  <Typography className="errorMessage">
                    {numberWithComma(contactCount)}
                    {contactCount <= 1
                      ? MESSAGES.ORDERS.CREATE.CONTACT_COUNT_TEXT
                      : MESSAGES.ORDERS.CREATE.CONTACTS_COUNT_TEXT}
                  </Typography>
                )}
              {errors.contacts && contactType !== "Upload" && (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {MESSAGES.ORDERS.CREATE.CONTACT_REQUIRED}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddContacts;
