// Request util for api call
import request from "~/utils/request";

// Actions Types
import { SET_CONTACTS_DATA, SET_CONTACTS_LOADER, SET_CONTACTS_LABELS, SET_RETURN_ADDRESSES_DATA } from "./action-types"

// Utiils
import { dateFormat } from "~/utils/date-format";
import { DEFAULT_PAGINATION } from "~/utils/constants";

const fetchContacts = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_CONTACTS_LOADER, payload: true });

        let url = '/contacts';

        let queryPayload = {
            page: payload?.pagination?.page ? payload.pagination.page : DEFAULT_PAGINATION.page,
            pageSize: payload?.pagination?.pageSize ? payload.pagination.pageSize : DEFAULT_PAGINATION.pageSize,
        }

        if (typeof payload?.isReturnAddress === 'boolean') {
            queryPayload.isReturnAddress = payload.isReturnAddress;
        }

        if (payload?.search?.length) {
            queryPayload.search = payload.search;
        }

        if (payload?.applyFilters) {
            const filterParams = Object.entries(payload.filters)
                .map(([key, value]) => {
                    if (key === 'lastMailedDate' || key === 'createdAt' || key === 'lastQrScannedDate') {
                        // If the value is an object, iterate over its properties
                        if (value) {
                            let date = Object?.entries(value)
                                .map(([nestedKey, nestedValue]) => `${JSON.stringify(nestedKey)}:${JSON.stringify(nestedValue)}`)
                                .join(', ');
                            queryPayload[key] = `{${date}}`;
                        }
                    } else if (key === 'tags' && value.length) {
                        queryPayload[key] = `[${value}]`;
                    }
                    else if (value.length) {
                        queryPayload[key] = `${JSON.stringify(value)}`;
                    }
                })
        }

        const response = await request.get(url, queryPayload);

        if (response.status === 200) {
            const combinedData = response.data.data.rows.map((contact) => ({
                ...contact,
                fullName: contact.fullName,
                createdAt: dateFormat(contact.createdAt),
                lastMailedStatus: contact.lastMailedStatus ? contact.lastMailedStatus : ' -',
                tags: contact.ContactLabels.map((label) => label.title),
                checked: false,
            }));
            const contacts = {
                rows: combinedData,
                count: response.data.data.count,
                totalRecordsInDB: response.data.data.totalRecordsInDB,
                to: response.data.data.to,
                from: response.data.data.from,
                currentPage: response.data.data.currentPage,
                lastPage: response.data.data.lastPage,
                perPage: response.data.data.perPage,
            }
            dispatch({ type: SET_CONTACTS_DATA, payload: contacts });
        }

        return response;
    } catch (error) {
        return error;
    } finally {
        dispatch({ type: SET_CONTACTS_LOADER, payload: false });
    }
}

const fetchReturnAddresses = (payload) => async (dispatch) => {
    try {
        const returnAddresses = await request.get("contacts", payload);
        dispatch({ type: SET_RETURN_ADDRESSES_DATA, payload: returnAddresses.data.data.rows });
    } catch (error) {
        return error;
    }
}

const fetchContactLabels = () => async (dispatch) => {
    try {
        const labels = await request.get("contacts/labels", { pageSize: 500 });
        dispatch({ type: SET_CONTACTS_LABELS, payload: labels.data.data.rows });
    } catch (error) {
        return error;
    }
};

const fetchContactCount = (payload) => async () => {
    try {
        const count = await request.post("contacts/labels/getContactsCount", payload);
        return count
    } catch (error) {
        return error.response;
    }
};

const fetchExpectedDeliveryDate = (payload) => async () => {
    try {
        const dated = await request.post("orders/get-expected-mailed-date", payload);
        return dated;
    } catch (error) {
        return error.response;
    }
};

const createContact = (payload) => async () => {
    try {
        const contact = await request.post("contacts", payload);
        return contact;
    } catch (error) {
        return error.response;
    }
};

const updateContact = (payload) => async () => {
    try {
        const contact = await request.put(`contacts/${payload.id}`, payload);
        return contact;
    } catch (error) {
        return error.response;
    }
};

const updateContactCustomFields = (contactId, payload) => async () => {
    try {
        const customFields = await request.post(`contacts/custom-fields/${contactId}`, payload);
        return customFields;
    } catch (error) {
        return error.response;
    }
};

const contactBulkActions = async (payload) => {
    try {
        const queryPayload = {}

        queryPayload.ids = payload.ids;
        queryPayload.action = payload.action;

        if (payload?.search?.length || payload?.applyFilters) {
            queryPayload.filters = {}
        }
        if (payload?.search?.length) {
            queryPayload.filters.search = payload.search;
        }

        if (payload?.applyFilters) {
            const filterParams = Object.entries(payload.filters)
                .map(([key, value]) => {
                    if (key === 'lastMailedDate' || key === 'createdAt' || key === 'lastQrScannedDate') {
                        // If the value is an object, iterate over its properties
                        if (value) {
                            queryPayload.filters[key] = value;
                        }
                    }
                    else if (value.length) {
                        queryPayload.filters[key] = value;
                    }
                })
        }

        const contactBulk = await request.post('contacts/bulk-actions', queryPayload);
        return contactBulk;
    } catch (error) {
        return error.response;
    }
};

const updateContactTags = (payload) => async () => {
    try {
        const queryPayload = {}

        queryPayload.contactIds = payload.contactIds;
        queryPayload.tagIds = payload.tagIds;
        queryPayload.action = payload.action;

        if (payload?.search?.length || payload?.applyFilters) {
            queryPayload.filters = {}
        }
        if (payload?.search?.length) {
            queryPayload.filters.search = payload.search;
        }

        if (payload?.applyFilters) {
            const filterParams = Object.entries(payload.filters)
                .map(([key, value]) => {
                    if (key === 'lastMailedDate' || key === 'createdAt' || key === 'lastQrScannedDate') {
                        // If the value is an object, iterate over its properties
                        if (value) {
                            queryPayload.filters[key] = value;
                        }
                    }
                    else if (value.length) {
                        queryPayload.filters[key] = value;
                    }
                })
        }
        const updateTags = await request.post(`contacts/labels/update-contact-association`, queryPayload);
        return updateTags;
    } catch (error) {
        return error.response;
    }
};

export { fetchContacts, fetchContactLabels, createContact, fetchContactCount, fetchExpectedDeliveryDate, updateContact, updateContactCustomFields, contactBulkActions, fetchReturnAddresses, updateContactTags }
