// Default messages
export const MESSAGES = {
  GENERAL_ERROR: "Internal Server Error",
  VIEW_PROOF_ERROR: "Unable to download the proof. Please try again.",
  NO_ROWS_TEXT: "No results found",
  NO_RESULTS_TEXT: "No results meet your current ",
  HIRE_DESIGNER: {
    TITLE: "Custom Design Form",
    SUBMIT_BUTTON: "Next",
    CHARGE_DIALOG: {
      TITLE: "Charge",
      Typography_START: "You'll be charged",
      Typography_END: "for this design.",
      PURCHASE_BUTTON: "Purchase Now",
      PURCHASE_FAILED: "Purchase failed due to low wallet balance",
    },
    PURCHASE_DIALOG: {
      TITLE: "Purchase Successfully",
      TYPOGRAPHY:
        "Thank you for your purchase! You will receive your proof via email from within two business days. Your proof edits can be submitted via email once the first proof has been received.",
      OK_BUTTON: "OK",
    },
  },
  AUTH: {
    INVALID_EMAIL: "Invalid email address",
    EMAIL_REQUIRED: "Email is required",
    INVALID_PASSWORD: `Must be at least 8 characters long and include at least 1 uppercase and lowercase letter, number, and symbol.`,
    PASSWORD_UPDATED_HEADING: "Password Reset",
    PASSWORD_UPDATED: "Your Password has been Successfully Reset!",
    PASSWORD_MATCH_ERROR: "Passwords must match",
    PASSWORD_REQUIRED: "Password is required",
    RESET_CODE: "A new code has been sent to ",
    NEWS_LETTER_TEXT:
      "I would like to receive product updates and promotional emails from Open Letter Connect",
    LAST_NAME_REQUIRED: "Last Name is required",
    FIRST_NAME_REQUIRED: "First Name is required",
    INDUSTRY_REQUIRED: "This field is required",
    COMPANY_REQUIRED: "This field is required",
    CRM_REQUIRED: "This field is required",
    ROLE_REQUIRED: "This field is required",
    ABOUT_REQUIRED: "This field is required",
  },
  SIGN_IN: {
    TITLE: "Sign in to Your Account",
    INPUT_LABEL_EMAIL: "Email",
    EMAIL_PLACEHOLDER: "Email Address",
    INPUT_LABEL_PASSWORD: "Password",
    FORGOT_BUTTON: "Forgot Password?",
    SIGN_UP_TEXT: `Don't have an Account? `,
    SIGN_UP_BUTTON: "Sign Up",
    SUBMIT_BUTTON: "Sign In",
    INVALID_EMAIL_OR_PASSWORD: "Invalid Email or Password!",
  },
  SIGN_UP: {
    TITLE: "Create Your Account",
    INPUT_LABEL_FIRST_NAME: "First Name",
    INPUT_LABEL_LAST_NAME: "Last Name",
    INPUT_LABEL_INDUSTRY: "What industry are you part of?",
    INDUSTRY_PLACEHOLDER: "Finance",
    INPUT_LABEL_COMPANY: "How large is your company?",
    COMPANY_PLACEHOLDER: "1-5",
    INPUT_LABEL_CRM: "What CRM do you use?",
    INPUT_LABEL_ROLE: "What is your role?",
    ROLE_PLACEHOLDER: "Business Owner",
    INPUT_LABEL_ABOUT: "How did you hear about us?",
    ABOUT_PLACEHOLDER: "Facebook",
    INPUT_LABEL_EMAIL: "Email",
    EMAIL_PLACEHOLDER: "Email Address",
    INPUT_LABEL_PASSWORD: "Password",
    INPUT_LABEL_CONFIRM_PASSWORD: "Confirm Password",
    AGREE_TEXT: "I agree to all ",
    TERMS_TEXT: "Terms & Conditions",
    SIGN_IN_TEXT: `Already have an Account? `,
    SIGN_IN_BUTTON: "Sign In",
    JOIN_BUTTON: "Join Your Team",
    SUBMIT_BUTTON: "Sign Up",
  },
  RESET_PASSWORD: {
    TITLE: "Reset Password",
    INPUT_LABEL_PASSWORD: "Password",
    INPUT_LABEL_CONFIRM_PASSWORD: "Confirm Password",
    SUBMIT_BUTTON: "Reset Password",
  },
  VERIFICATION: {
    TITLE: "Verification",
    HEADING: "Please enter the verification code sent to you via email.",
    INPUT_LABEL_CODE: "Verification Code",
    SUBMIT_BUTTON: "Verify",
    RESEND_BUTTON: "Resend Code",
  },
  FORGOT_PASSWORD: {
    TITLE: "Forgot Password?",
    HEADING:
      "Please enter your email and a verification code will be sent to you.",
    INPUT_LABEL_EMAIL: "Email",
    EMAIL_PLACEHOLDER: "Email Address",
    SUBMIT_BUTTON: "Send Email",
  },
  MODE: {
    TEST_LABEL: "Test",
    LIVE_LABEL: "Live",
  },
  UNVERIFIED_EMAIL: {
    TITLE: "Verify Your Email",
    HEADING: "Please Verify your email to access Open Letter Connect.",
    RESEND_BTN_TEXT: "Resend Verification",
  },
  CONTACTS: {
    EXPORT_CONTACTS: "Are you sure you want to export these Contacts?",
    EXPORT_SINGLE_CONTACTS: "Are you sure you want to export this Contact?",
    DELETE_CONTACTS: "Are you sure you want to delete these Contacts?",
    DELETE_SINGLE_CONTACTS: "Are you sure you want to delete this Contact?",
    SEARCH_PLACE_HOLDER: "Search by name, company, address, phone, or email",
    END_DATE_REQUIRED: "Please select the end date.",
    FILTERS_REQUIRED: "Please select any filters.",
    SINGLE_EXPORT_HEADER: "Export",
    MULTI_EXPORT_HEADER: "Export list",
    SINGLE_DELETE_HEADER: "Delete",
    MULTI_DELETE_HEADER: "Delete list",
    CLEAR_FILTER_BTN_TEXT: "Clear Filters",
    CREATE_BTN_TEXT: "Create",
    ACTIONS_BTN_TEXT: "Actions",
    ACTIONS:{
      EXPORT_CONTACTS: "Export Contacts",
      DELETE_CONTACTS: "Delete Contacts",
      ADD_TAGS: "Add Tag(s)",
      REMOVE_TAGS: "Remove Tag(s)",
    },
    EXPORT_BTN_TEXT: "Export",
    TAGS_ADD_PLACEHOLDER: "Select or create a Tag",
    TAGS_REMOVE_PLACEHOLDER: "Select Tags",
    TAGS_UPDATE_TEXT: "Add Tag(s)",
    TAGS_REMOVE_TEXT: "Remove Tag(s)",
    TAGS_UPDATE_BTN_TEXT: "Yes",
    TAGS_REMOVE_NOTE_TEXT: "Are you sure you want to remove tags for selected contacts?",
    TAGS_UPDATE_ERROR: "Please select at least one tag.",
    TAGS_UPDATE_SUCCESS_MESSAGE: "Your request is in progress and will be completed shortly.",
    IMPORT_BTN_TEXT: "Import",
    DELETE_BTN_TEXT: "Delete",
    EDIT_BTN_TEXT: "Edit",
    SAVE_BTN_TEXT: "Save",
    EXPORT_MESSAGE: `You'll receive a confirmation email when the export is completed.`,
    DELETE_MESSAGE: `You'll receive a confirmation email when the delete is completed.`,
    CONTACT_DETAIL_TITLE: "Contact Details",
    CONTACT_BACK_ARROW: "Contacts",
    SELECT_ALL_ITEMS: "Select All",
    SELECT_VISIBLE_ITEMS: "Select Visible",
    SELECT_NONE: "Select None",
    EVENTS_LOGS: {
      TITLE: "Event Log",
      DUMMY_DATA: {
        ADDRESS_1_LABEL: "605 Abdul Haque Rd, Block H-3 Block H 3 Phase 2.",
        EVENT_1: " Created",
        DATE_1: "Nov 15, 2023 06:39:39 AM",
        ADDRESS_2_LABEL: "605 Abdul Haque Rd, Block H-3 Block H 3 Phase 2.",
        EVENT_2: " Updated",
        DATE_2: "Nov 15, 2023 09:39:39 AM",
      },
    },
    NO_CONTACTS_SCREEN: {
      TITLE: "You have no Contacts in your database",
      CREATE_CONTACT_BUTTON: "Create New Contact",
      IMPORT_BTN_TEXT: "Import Contacts",
    },
    ADVANCE_FILTERS: {
      HEADING: "Filters",
      SEARCH_TAG: "Select Tag",
      CREATE_TAG: "Select or create a Tag",
      NO_SEARCH_TAG: "No Tags to show",
      ADDRESS_STATUS: "Address Status",
      NO_ADDRESS_STATUS: "No Address Status to show",
      MAILED_STATUS: "Last Mailed Status",
      NO_MAILED_STATUS: "No Last Mailed Status to show",
      MAILED_DATE: "Last Mailed Date",
      NO_TAGS_CONTACTS: "No Tags Assigned",
      CREATED_DATE: "Created Date",
      QR_SCAN_DATE: "Last QR Code Scan Date",
      TAGS_LABEL: "Tags",
      CANCEL_BUTTON: "Clear",
      SUBMIT_BUTTON: "Apply",
    },
    DELETE: {
      TITLE: "Delete Contact",
      HEADING: "Are you sure you want to delete this contact?",
      MESSAGE:
        "This contact will be deleted from your Contacts database but will still be included in associated mailing campaigns.",
      CANCEL_BUTTON: "No",
      SUBMIT_BUTTON: "Yes",
    },
    IMPORT: {
      TITLE: "Import Contacts",
      OFFER_PERCENTAGE: "Choose an Offer Percentage",
      OFFER_PERCENTAGE_DESCRIPTION:
        "Upload a list to generate the first 5 offer values",
      OFFER_PERCENTAGE_TABLE:{
        TITLE: "Find Your Offer Percentage",
        OFFER_LEVEL: "Offer Level",
        OFFER_1: "Offer 1 (50%)",
        OFFER_2: "Offer 2 (79%)",
        OFFER_3: "Offer 3 (83%)",
        OFFER_4: "Offer 4 (90%)",
        CUSTOM: "Custom",
        SUBMIT_BUTTON: "Choose Offer",
        NO_DATA_FOUND: "no data found",
      },
      UPLOAD_FILE: {
        TITLE: "Upload a File",
        DRAG_TEXT: " or drag and drop",
        FILE_TYPE: "CSV Files only",
        REQUIRED: "*File upload is required",
        REPLACE: "Please remove the current file to add new one",
        EMPTY_FILE: "Empty file is not allowed, please upload another one",
        INVALID_FILE: "File type must be CSV.",
      },
      DOWNLOAD: "Download",
      SAMPLE_FILE: " our sample CSV file here.",
      SMARTY_VALIDATIONS: {
        REQUIRED: "*",
        VALID_CONTACTS: " Contacts will be imported",
        VALID_CONTACT: " Contact will be imported",
        MISSING_CONTACT: " contact will be skipped due to missing information",
        MISSING_CONTACTS:
          " contacts will be skipped due to missing information",
        DUPLICATES_CONTACT: " contact will be skipped due to duplicates",
        DUPLICATES_CONTACTS: " contacts will be skipped due to duplicates",
        PROCESSED_RESULT: "Processed Result",
        PROCESSED_RESULT_EMAIL_TEXT: "You will be emailed a CSV of the skipped records once list is imported.",
      },
      ACTIONS: {
        SUCCESS_TITLE: "Contacts are Importing",
        SUCCESS_MESSAGE: " Contact will be imported",
        SUCCESS_MESSAGE_MULTIPLE: " Contacts will be imported",
        SUCCESS_DESCRIPTION:
          "You will receive an email when your contact has been imported into your database.",
        SUCCESS_DESCRIPTION_MULTIPLE:
          "You will receive an email when your contacts have been imported into your database.",
        CANCEL_DESCRIPTION: "You will lose your changes after canceling.",
        CANCEL_TITLE: "Confirm Cancel Import",
        CANCEL_MESSAGE: "Are you sure you want to cancel this Import?",
        DONE_TEXT: "OK",
        BACK_TEXT: "Go Back",
        CANCEL_TEXT: "Cancel Import",
      },
      CONTACTS_MAPPING: {
        TITLE: "Map your CSV file",
        MATCH_LABEL: "Match",
        OLC_LABEL: "OLC Fields",
        CSV_LABEL: "CSV Columns",
        SAMPLE_LABEL: "Sample Data",
        REQUIRED: "*",
        REQUIRED_TEXT: " is required",
        SUBMIT_BUTTON: "Process CSV",
        CANCEL_BUTTON: "Cancel",
        CLOSE_BUTTON: "Close",
      },
      CANCEL_BUTTON: "Cancel",
      SUBMIT_BUTTON: "Import",
      CONTINUE: "Continue",
    },
    CREATE: {
      TITLE: "Create Contact",
      FIRST_NAME_LABEL: "First Name",
      LAST_NAME_LABEL: "Last Name",
      COMPANY_LABEL: "Company",
      ADDRESS_1_LABEL: "Address Line 1*",
      ADDRESS_2_LABEL: "Address Line 2",
      PHONE_VALIDATION: "Phone number is not valid",
      CITY_LABEL: "City*",
      STATE_LABEL: "State*",
      ZIP_LABEL: "Zip Code*",
      PHONE_LABEL: "Phone Number",
      EMAIL_LABEL: "Email Address",
      TAGS_LABEL: "Tags",
      TAGS_PLACEHOLDER: "Search a Tag",
      CREATE_SEARCH_TAGS_PLACEHOLDER: "Search or Create a Tag",
      FIRST_NAME_OR_COMPANY_REQUIRED:
        "The first name or company name field is required",
      COMPANY_MAX_LENGTH: "Company name must be less than 100 characters",
      ADDRESS_1__REQUIRED: "Address Line 1 is required",
      CITY_REQUIRED: "City name is required",
      STATE_REQUIRED: "State name is required",
      ZIP_REQUIRED: "Zip code is required",
      SUBMIT_BUTTON: "Create",
      CANCEL_BUTTON: "Cancel",
      PROPERTY_ADDRESS: "Property Address",
      PROPERTY_CITY: "Property City",
      PROPERTY_STATE: "Property State",
      PROPERTY_ZIP: "Property Zip Code",
      PROPERTY_OFFER: "Property Offer",
      RETURN_ADDRESS_LABEL: "Use as Return Address",
    },
    EDIT: {
      FIRST_NAME_OR_COMPANY: "The first name or company name field is required",
      FIRST_NAME_LABEL: "First Name",
      LAST_NAME_LABEL: "Last Name",
      COMPANY_LABEL: "Company",
      COMPANY_MAX_LENGTH: "Company name must be less than 100 characters",
      ADDRESS_1_LABEL: "Address Line 1",
      ADDRESS_2_LABEL: "Address Line 2",
      CITY_LABEL: "City",
      STATE_LABEL: "State",
      ZIP_LABEL: "Zip Code",
      PHONE_LABEL: "Phone Number",
      TAGS_LABEL: "Tags",
      EMAIL_LABEL: "Email Address",
      ADDRESS_STATUS: "Address Status",
      TOTAL_MAILED: "Total Mailed",
      LAST_MAILED_STATUS: "Last Mailed Status",
      LAST_MAILED_DATE: "Last Mailed Date",
      DATE_LABEL: "Date Created",
      USE_AS_RETURN_ADDRESS: "Use as Return Address",
      PROPERTY_ADDRESS: "Property Address",
      PROPERTY_CITY: "Property City",
      PROPERTY_STATE: "Property State",
      PROPERTY_ZIP: "Property Zip Code",
      PROPERTY_OFFER: "Property Offer",
    },
  },
  TEMPLATE: {
    SEARCH_PLACE_HOLDER: "Search by template name or template ID",
    NAME_REQUIRED: "Template Name is required",
    TYPE_REQUIRED: "Template Type is required",
    NAME_LESS_50: "Template Name should be less than or equal to 50 characters",
    LIMIT_MODAL: {
      TITLE: "Limit Reached",
      HEADING: "You've reached your template limit",
      SUB_HEADING: "Please Upgrade your subscription to add more templates",
      CANCEL_BUTTON: "Cancel",
      SUBMIT_BUTTON: "View Plans",
    },
    DELETE: {
      TITLE: "Delete Template",
      HEADING: "Are you sure you want to delete this Template?",
      PARAGRAPH:
        "This template will be deleted from your Templates list but will still be included in associated orders.",
    },
    SAVE: {
      TITLE: "Confirm Save Template",
      HEADING: "Are you sure you want to save this template?",
      PARAGRAPH:
        "The updates provided will be used right away for any orders referencing this template.",
    },
    CANCEL: {
      TITLE: "Confirm Cancel Template",
      HEADING: "Are you sure you want to cancel this Template?",
      PARAGRAPH: "You will lose your changes after canceling.",
    },
    TEMPLATE_BUILDER: {
      CREATED: "Template Created Successfully",
      UPDATED: "Template Updated Successfully",
    },
  },
  ORDERS: {
    SEARCH_PLACE_HOLDER: "Search by order name or order ID",
    CLEAR_FILTER_BTN_TEXT: "Clear Filters",
    CANCEL_ORDER_BTN_TEXT: "Cancel Order",
    EXPORT_BTN_TEXT: "Export",
    PAY_AND_PROCESS_BTN_TEXT: "Pay and Process",
    END_DATE_REQUIRED: "Please select the end date.",
    FILTERS_REQUIRED: "Please select any filters.",
    ORDER_NAME_TOOLTIP: "Order Name:",
    NO_ORDERS_SCREEN: {
      TITLE: "You have no Orders in your database",
      CREATE_ORDER_BUTTON: "Create New Order",
    },
    ADVANCE_FILTERS: {
      HEADING: "Filters",
      CREATED_BY_LABEL: "Created By",
      CREATED_BY_PLACEHOLDER: "Search by user name/email",
      NO_CREATED_BY: "No Created By to show",
      PRODUCT_TYPE_LABEL: "Product type",
      NO_PRODUCT_TYPE: "No Product type to show",
      SOURCE_LABEL: "Source",
      NO_SOURCE: "No Source to show",
      ORDER_STATUS_LABEL: "Order Status",
      NO_ORDER_STATUS: "No Order Status to show",
      PAYMENT_STATUS_LABEL: "Payment Status",
      NO_PAYMENT_STATUS: "No Payment Status to show",
      CREATED_DATE_LABEL: "Created Date",
      SCHEDULED_DATE_LABEL: "Scheduled Date",
      CANCEL_BUTTON: "Clear",
      SUBMIT_BUTTON: "Apply",
    },
    CREATE: {
      TITLE: "Create New Order",
      DELIVERABLES_REQUIRED:
        "You should have at least 1 deliverable contact before submitting the order.",
      TEMPLATE_LABEL: "Select Template*",
      TEMPLATE_REQUIRED: "Template is required",
      PRODUCT_TYPE_LABEL: "Product Type: ",
      ENVELOPE_TYPE_LABEL: "Envelope Type: ",
      POSTCARD_SIZE_LABEL: "Postcard Size: ",
      POSTAGE_TYPE_LABEL: "Postage Type*",
      OFFER_PERCENTAGE_LABEL: "Enter Offer Percentage*",
      OFFER_PERCENTAGE_DESCRIPTION: "Unsure of your offer percentage?",
      OFFER_PERCENTAGE_LINK: "Click here",
      OFFER_PERCENTAGE_PLACEHOLDER: "Type in a Number between 0-100",
      OFFER_PERCENTAGE_REQUIRED: "Valid Offer Percentage is required",
      MAX_RECORDS: 5,
      POSTAGE_TYPE_REQUIRED: "Postage Type is required",
      ENVELOPE_STYLE_LABEL: "Envelope Style* ",
      ENVELOPE_STYLE_REQUIRED: "Envelope Style is  required",
      VIEW_PROOF_LABEL: "Download Proof",
      HTML_TEMPLATE_LABEL:
        "*HTML Templates cannot display an image. Click to Download proof.",
      CONTACT_COUNT_TEXT: " Contact Selected ",
      CONTACTS_COUNT_TEXT: " Contacts Selected ",
      CONTACT_REQUIRED: "Contact is required",
      RETURN_ADDRESS_TITLE: "Return Address*",
      RETURN_ADDRESS_REQUIRED: "This field is required",
      DATE_LABEL: "Scheduled Date* ",
      DATE_REQUIRED: "This field is required",
      DELIVERY_DATE: "Mail Date: ",
      ORDER_NAME: "Order Name (Optional)",
      CANCEL_BUTTON: "Cancel",
      NEXT_BUTTON: "Next",
      SUBMIT_BUTTON: "Submit Order",
      LEARN_TITLE: "Learn More",
      BACK_BUTTON: "Back",
      CONTACTS: {
        CONTACT_LABEL: "Select Contacts*",
        SINGLE: "Single Contact",
        MULTIPLE: "Multiple Contacts",
        UPLOAD: "Upload Contacts",
        CREATE: "Create New Contact",
        SUBMIT_BUTTON: "Create",
        CANCEL_BUTTON: "Cancel",
      },
      REVIEW: {
        COST_HEADING: "Estimated Cost ",
        COST_TITLE:
          "Cost is dependent on merge strictness and the deliverability of your contacts when the order is processed. ",
        HEADING: "Please Inspect your order thoroughly for the following:",
        DELIVERABLE_TITLE: " deliverable contact",
        DELIVERABLES_TITLE: " deliverable contacts",
        DOWNLOAD_PROOF: "Download the Proof",
        DOWNLOAD_PROOF_VALIDATION:
          "Please download the proof before proceeding with order creation.",
        MAILER_TITLE: " per mailer",
        PROOF_TITLE: "Proof is displaying all content correctly",
        SPELLING_TITLE: "There are no spelling errors",
        VARIABLE_TITLE:
          "All variable fields in my template are correct and merged with correct contact data.",
        REQUIRED: "All are required",
        HTML_LABEL: "Download Proof here...",
        PROOF_LABEL: "Download Proof",
        DESCRIPTION:
          "Orders are charged on their Scheduled Date, not when they are submitted. Orders are eligible to be canceled up to their Scheduled Date.",
      },
      SUBMIT_MODAL: {
        TITLE: "Order Submitted",
        CANCEL_TITLE: "Confirm Cancel Order?",
        HEADING: "Your Order has been placed Successfully",
        CANCEL_HEADING: "Are you sure you want to cancel this Order?",
        DESCRIPTION:
          "You will receive an email when your contacts have been imported into your database.",
        CANCEL_DESCRIPTION: "You will lose your changes after canceling.",
        BACK_BUTTON: "Go Back",
        SUBMIT_BUTTON: "OK",
        CANCEL_BUTTON: "Cancel Order",
      },
    },
    DETAIL: {
      OVERVIEW: {
        NO_DATA: "No data",
        NO_DATA_ANALYTICS:
          "Analytics are updated when order is in Mailed status",
        MAILED: "Mailed",
        IN_TRANSIT: "In Transit",
        DELIVERED: "Delivered",
        RE_ROUTE: "Re-Routed",
        RETURNED_TO_SENDER: "Returned to Sender",
        SCANNED: "Scanned",
        NOT_SCANNED: "Not Scanned",
        DAY_ELAPSED: "Day Elapsed",
        CANCEL_ORDER_BUTTON: "Cancel Order",
        VIEW_PROOF_BUTTON: "Download Proof",
        TOTAL_CONTACTS: "Total Contacts",
        DELIVERABLES: "Deliverables",
        UNDELIVERABLES: "Undeliverables",
        TOTAL_COST: "Total Cost",
        ADD_FUND_AND_PROCESS_ORDER: "Add Funds & Process Order",
        PAY_AND_PROCESS_BUTTON: "Pay & Process Order",
        AMOUNT_NOT_ENOUGH_FOR_ORDER:
          "*Amount added to your wallet isn't enough for your order",
      },
      CONTACTS: {
        FILTER: {
          TITLE: "Filters",
          FILTER_REQUIRED: "Please select any filter",
          ADDRESS_STATUS: "Address Status",
          MAILED_STATUS: "Mailed Status",
          DELIVERED_DATE: "Delivered Date",
          CANCEL_BUTTON: "Clear",
          SUBMIT_BUTTON: "Apply",
        },
        REMOVE: {
          CONTACT: "Remove Contact(s)",
          MODAL: {
            REMOVE_CONTACT: {
              SINGLE_REMOVE_HEADING: "Remove Contact?",
              MULTI_REMOVE_HEADING: "Remove Contacts?",
              REMOVE_CONTACTS:
                "Are you sure you want to remove these Contacts?",
              REMOVE_SINGLE_CONTACTS:
                "Are you sure you want to remove this Contact?",
              NOTE: `This action cannot be undone. Please proceed with caution.`,
            },
          },
        },
        TABLE: {
          SELECT_ALL_ITEMS: "Select All",
          SELECT_VISIBLE_ITEMS: "Select Visible",
          SELECT_NONE: "Select None",
          NO_RESULT: "No results with current filters.",
        },
        CLEAR_FILTERS: "Clear Filters",
      },
      MODALS: {
        CANCEL: {
          HEADING: "Cancel Order?",
          MESSAGE: "Are you sure you want to cancel this order?",
          MULTI_MESSAGE: "Are you sure you want to cancel these order?",
          SUCCESS_BUTTON: "Yes",
          SUB_MESSAGE:
            "This action cannot be undone. You will not be charged for this order",
          MULTI_SUB_MESSAGE:
            "This action cannot be undone. You will not be charged for these orders",
        },
        PAY_AND_PROCESS: {
          HEADING: "Process Order",
          MESSAGE: "Are you sure you want to proceed?",
          SUCCESS_BUTTON: "Process Order",
          SUB_MESSAGE:
            "Your order will be resubmitted and processed. Your order is scheduled for ",
          MULTI_SUB_MESSAGE:
            "Your orders will be resubmitted and processed. Your orders is scheduled for ",
        },
        EXPORT: {
          HEADING: "Export Order",
          MESSAGE: "Are you sure you want to export this order?",
          MULTI_MESSAGE: "Are you sure you want to export these order?",
          SUCCESS_BUTTON: "Yes",
        },
        ADD_FUNDS: {
          HEADING: "Insufficient Funds",
          MESSAGE: "Not enough fund in wallet",
          SUCCESS_BUTTON: "Add Funds",
          SUB_MESSAGE: "Please add funds before processing this order",
        },
      },
    },
  },
  ACCOUNT: {
    PROFILE: {
      TITLE: "Profile",
      USER_HEADING: "User Information",
      NAME_LABEL: "Name: ",
      EMAIL_LABEL: "Email: ",
      ACCOUNT_HEADING: "Account Information",
      COMPANY_LABEL: "Company: ",
      ADDRESS_1_LABEL: "Address Line 1: ",
      ADDRESS_2_LABEL: "Address Line 2: ",
      CITY_LABEL: "City: ",
      STATE_LABEL: "State: ",
      ZIP_LABEL: "Zip Code: ",
      EDIT_BTN_TEXT: "Edit",
      CHANGE_PASSWORD_BTN_TEXT: "Change Password",
      EDIT_NAME: {
        TITLE: "Edit User Name",
        FIRST_NAME_LABEL: "First Name",
        LAST_NAME_LABEL: "Last Name",
        LAST_NAME_SPECIAL_CHARS: "Last Name cannot contain special characters",
        FIRST_NAME_SPECIAL_CHARS:
          "First Name cannot contain special characters",
        FIRST_NAME_REQUIRED: "First Name is required",
        LAST_NAME_REQUIRED: "Last Name is required",
        FIRST_NAME_LIMIT: "First name must be less than 30 characters",
        LAST_NAME_LIMIT: "Last name must be less than 30 characters",
        CANCEL_BUTTON: "Cancel",
        SUBMIT_BUTTON: "Save",
      },
      EDIT_ACCOUNT_INFO: {
        TITLE: "Edit Account Information",
        COMPANY_LABEL: "Company",
        ADDRESS_1_LABEL: "Address Line 1",
        ADDRESS_2_LABEL: "Address Line 2",
        CITY_LABEL: "City",
        STATE_LABEL: "State",
        ZIP_LABEL: "Zip Code",
        COMPANY_LIMIT: "Company name must be less than 100 characters",
        ADDRESS_1_LIMIT: "Address Line 1 must be less than 100 characters",
        ADDRESS_2_LIMIT: "Address Line 2 must be less than 100 characters",
        CITY_LIMIT: "City name must be less than 40 characters",
        STATE_LIMIT: "State name must be less than 40 characters",
        ZIP_LIMIT: "Zip Code must be less than 10 characters",
        PHONE_LIMIT: "Phone number must be less than 20 characters",
        CANCEL_BUTTON: "Cancel",
        SUBMIT_BUTTON: "Save",
      },
      CHANGE_PASSWORD: {
        TITLE: "Change Password",
        OLD_PASSWORD_LABEL: "Old Password*",
        NEW_PASSWORD_LABEL: "New Password*",
        CONFIRM_PASSWORD_LABEL: "Confirm Password*",
      },
    },
    USERS: {
      TITLE: "Users",
      INVITE_BTN_TEXT: "Invite User",
      NO_RESULT_TEXT: "No Users to show.",
      LIMIT_MODAL: {
        TITLE: "Limit Reached",
        HEADING: `You’ve reached your Users limit.`,
        SUB_HEADING:
          "Please upgrade your Subscription to access more premium features.",
        DEFAULT_MODAL_HEADING: `You've reached your limit!`,
        DEFAULT_MODAL_SUB_HEADING:
          "Please upgrade your subscription to access more premium features",
        CANCEL_BUTTON: "Cancel",
        SUBMIT_BUTTON: "View Plans",
      },
      INVITE_USER: {
        TITLE: "Invite User",
        EMAIL_LABEL: "Email Address",
        CANCEL_BUTTON: "Cancel",
        SUBMIT_BUTTON: "Invite",
      },
      EDIT_USER_ROLE: {
        TITLE: "Edit User",
        ROLE_LABEL: "Role",
        CANCEL_BUTTON: "Cancel",
        SUBMIT_BUTTON: "Save",
      },
      DELETE_USER: {
        TITLE: "Delete User",
        HEADING: "Are you sure you want to Delete this User?",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
    },
    WALLET: {
      TITLE: "Wallet",
      EXPORT: {
        SINGLE_EXPORT_HEADER: "Export",
        MULTI_EXPORT_HEADER: "Export list",
        EXPORT_TRANS: "Are you sure you want to export these Transactions?",
        EXPORT_SINGLE_TRANS:
          "Are you sure you want to export this Transaction?",
        EXPORT_MESSAGE: `You'll receive a confirmation email when the export is completed.`,
        EXPORT_BTN_TEXT: "Export",
        SELECT_ALL_ITEMS: "Select All",
        SELECT_VISIBLE_ITEMS: "Select Visible",
        SELECT_NONE: "Select None",
      },
      STRIPE: {
        ADD_PAYMENT_METHOD: "Add Payment Method",
        METHOD_ADDED: "Payment method added successfully",
        METHOD_FAILED: "Failed to add payment method",
        METHOD_DELETE_SUCCESS: "Payment method delete successfully",
        METHOD_DELETE_ERROR: "Payment method delete failed",
        DEFAULT_CHANGE_SUCCESS: "Default payment method changed successfully",
        DEFAULT_CHANGE_ERROR: "Default payment method change failed",
        ADD_FUNDS: "Add Funds",
        REMOVE_FUNDS: "Remove Funds",
        TRANSACTIONS: "Transactions",
        UPDATE_AUTOMATIC_FUNDING: "Update Automatic Funding",
        UPDATE_AUTO_FUNDING: "Update Auto Funding",
        MIN_AUTO_FUNDING: "Min. Auto Funding:",
        UPDATE_MIN_AUTO_FUNDING: "(Update)",
        UPDATE_AUTO_FUNDING_SUCCESS: "Automatic funding amount updated",
        ENABLE_AUTO_FUNDING: "Enable Automatic Funding",
        DISABLE_AUTO_FUNDING: "Disable Automatic Funding",
        FUNDS_ADDED: "Funds Added",
        FUNDS_REMOVED: "Funds Removed",
        ADD_FUNDS_SUCCESS: "Funds have been added to your Wallet!",
        ADD_FUNDS_ERROR: "Something went wrong while adding funds",
        ENTER_AMOUNT: "Enter Amount",
        DECREASE_WALLET_BALANCE_BY: "Decrease wallet balance by",
        REASON_FOR_DECREASE: "Reason for this decrease (Optional)",
        ENTER_MIN_AUTO_AMOUNT: "Enter Minimum Auto Funding Amount",
        REMOVE_FUNDS_CONFIRM: "Submit",
        CONFIRM: "Confirm & Pay",
        ENABLE_AUTO_FUND: "Enable Automatic Funding for Orders",
        AUTO_FUNDING_TITLE_1: `Automatic Funding will ensure all of your orders do
        not fail due to not enough funds in your Wallet.`,
        AUTO_FUNDING_TITLE_2: `If you do not have enough Wallet funds, OLC will
        charge your credit card for the remaining amount to
        process your order. The minimum charge will be for
        $50.00.`,
        YOUR_BALANCE: "Your Balance",
        DISABLED: "Disabled",
        ENABLED: "Enabled",
        ADD_FUNDING_VALIDATION: "Amount should be greater than or equal to 50.",
        AUTO_FUNDING_ENABLED_SUCCESS: "Automatic funding enabled successfully",
        AUTO_FUNDING_DISABLED_SUCCESS:
          "Automatic funding disabled successfully",
        YOUR_PAYMENT_METHOD: "Your Payment Methods",
        PAYMENT_METHOD_ERROR: "*At least one payment method is required.",
        PAYMENT_METHOD_REQUIRED:
          "Please add payment method first before adding the funds",
        AUTO_FUNDING: "Automatic Funding",
        // CONFIRM: "Confirm",
        CANCEL: "Cancel",
        DELETE_CONFIRMATION: "Are you sure you want to delete",
        NO_TRANSACTION: " No Transactions to show.",
        UNAUTHORIZED_ADD_FUND:
          "Only the Admins on your account are authorized to add funds to the wallet",
        UNAUTHORIZED_ADD_PAYMENT_METHOD:
          "Only the Admins on your account are authorized to add payment methods",
        UNAUTHORIZED_DELETE_PAYMENT_METHOD:
          "Only the Admins on your account are authorized to delete payment methods",
      },
    },
    PLANS: {
      AVAILABLE_PLANS_HEADING: "Available Plans",
      PRODUCT_PRICING_HEADING: "Product Pricing",
      HEADER: {
        HEADING:
          "You can change your billing frequency below, or change plans by selecting the button below your desired plan ",
        FREE_MONTHS: "2 MONTHS FREE",
      },
      UPGRADE: {
        TITLE: "Upgrade Subscription",
        SUBMIT_BUTTON: "Confirm",
        CANCEL_BUTTON: "Cancel",
      },
      DOWNGRADE: {
        TITLE: "Downgrade Subscription",
        HEADING:
          "By downgrading your account, you will lose the following features: ",
        NOTE: "You have too many of the following to downgrade your plan. You will need to remove following before ",
        SUBMIT_BUTTON: "Confirm",
        CANCEL_BUTTON: "Cancel",
      },
      PLAN_CARDS: {
        LABELS: {
          USERS: "Users",
          CONTACTS: "Contacts",
          TEMPLATES: "Templates",
          CUSTOM_FIELDS: "Custom Fields",
          WEBHOOKS: "Webhooks",
          TAGS: "Tags",
          MAILING: "Monthly Mailings",
          TRANSIT: "In-Transit Mail Tracking",
          ROLES_PERMISSION: "User Roles & Permissions",
          ANALYTICS: "Analytics Dashboard",
          MAILER: "Specialty Mailers",
        },
        CURRENT_PLAN_BTN: "Current Plan",
        UPGRADE_PLAN_BTN: "Upgrade",
        DOWNGRADE_PLAN_BTN: "Downgrade",
      },
      INVOICE: {
        HEADING: "Invoices",
        NO_RESULT_TEXT: "No Invoice to show",
        NEXT_BUTTON: "Next",
        PREVIOUS_BUTTON: "Previous",
      },
    },
    CANCEL_BUTTON: "Cancel",
    SUBMIT_BUTTON: "Change",
  },
  SETTINGS: {
    API_KEYS: {
      TITLE: "API Keys",
      NO_API_KEYS: "No API Keys to show.",
      COPY_KEY: "Key copied successfully.",
      NO_EXPIRY: "No Expiration",
      CREATE_BUTTON_TEXT: "Create",
      RESPONSE_ALREADY_EXIST: "API Key already exists with same name.",
      SOURCE_ALREADY_EXIST_IN_DB: "API Key already exists with same source.",
      NAME_ALREADY_EXIST: "API Key already exists with same name",
      INVALID_GHL_API_KEY: "Invalid GoHighLevel API Key",
      SOURCE_ALREADY_EXIST: "API Key already exists with same source",
      GHL_API_KEY_INVALID: "Invalid GoHighLevel API Key",
      SOURCE_REQUIRED: "API Key already exists with same source.",
      CREATE: {
        TITLE: "Create API Key",
        NAME_LABEL: "Name*",
        GHL_API_KEY_LABEL: "GHL API Key (Optional)",
        GHL_API_KEY_TOOLTIP:
          "Add your GHL API Key to enable USPS tracking webhooks for your contacts.",
        SOURCE_LABEL: "Source*",
        SOURCE_REQUIRED: "Source is required",
        DATE_LABEL: "Expiry Date (Optional)",
        DATE_PLACEHOLDER: "Expiry Date",
        NAME_REQUIRED: "Name is required",
        NAME_LESS_255: "Name must be less than 255 characters",
      },
      EDIT: {
        TITLE: "Edit API Key",
      },
      DELETE: {
        TITLE: "Delete API Key",
        HEADING: "Are you sure you want to Delete this API Key?",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
      ROTATE: {
        TITLE: "Rotate API Key",
        DATE_LABEL: "Expiry Date (Optional)",
        DATE_PLACEHOLDER: "Expiry Date",
      },
      CANCEL_BUTTON: "Cancel",
      SUBMIT_BUTTON: "Save",
    },
    GENERAL_SETTINGS: {
      TITLE: "General Settings",
      EDIT_BTN_TEXT: "Edit",
      ADDRESS_STRICT_LABEL: "Address Strictness",
      RETURN_ADDRESS_NAME_LABEL: "Return Address Configuration",
      MERGE_STRICT_LABEL: "Merge Variable Strictness",
      PROPERTY_STRICT_LABEL: "Property Address Information",
      STRICT_LABEL: "Strict",
      NORMAL_LABEL: "Normal",
      RA_NAME_LABEL: "First Name",
      RA_LAST_NAME_LABEL: "Last Name",
      RA_COMPANY_LABEL: "Company Name",
      RA_ADDRESS_LABEL: "Full Address",
      RA_ERROR_MESSAGE:
        "If any field in Return Address Configuration is selected, Either First Name or Company Name must also be selected.",
      PROPERTY_INCLUDE_LABEL: "Include",
      PROPERTY_EXCLUDE_LABEL: "Exclude",
      RATE_LABEL: "Rate Limits",
      PRODUCT_LABEL: "Product:",
      PRODUCT_VALUE_1: "Print & Mail",
      PRODUCT_VALUE_2: "Address Verification",
      RATE_LIMIT_LABEL: "Rate Limit:",
      RATE_VALUE_1: "150 / 5 seconds",
      RATE_VALUE_2: "300 / 5 seconds",
      LEARN_TITLE: "Learn More",
      CANCEL_BUTTON: "Cancel",
      SUBMIT_BUTTON: "Save",
    },
    TAGS: {
      TITLE: "Tags",
      TAGS_REQUIRED: "Please add tags before submitting!",
      NO_TAGS: "No Tags to show",
      CREATE_BUTTON_TEXT: "Create",
      CREATE: {
        TITLE: "Create Tags",
        TAG_LABEL: "Tags",
        TAG_PLACEHOLDER: "Type to create a tag. Use a comma to separate tags.",
        TAG_LIMIT: "Tag name should be less than 35 letters",
        SUBMIT_BUTTON: "Add",
      },
      EDIT: {
        TITLE: "Edit Tag",
        TAG_LABEL: "Tags",
        TAG_PLACEHOLDER: "Type to update a tag",
        TAG_REQUIRED: "Tag name required",
        TAG_LIMIT: "Tag name should be less than 35 letters",
        SUBMIT_BUTTON: "Save",
      },
      DELETE: {
        TITLE: "Delete Tag",
        HEADING: "Are you sure you want to Delete this Tag?",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
      CANCEL_BUTTON: "Cancel",
      SUBMIT_BUTTON: "Save",
    },
    WEBHOOKS: {
      TITLE: "Webhooks",
      CREATE_BUTTON_TEXT: "Create",
      NO_RESULT_TEXT: "No Webhooks to show.",
      NO_WEBHOOK_LOGS_TEXT: "No Webhooks Logs to show.",
      LOGS: {
        TITLE: "Webhook Log",
        DATE_LABEL: "Date",
        NO_RESULT_TEXT: "No Webhooks Logs to show.",
        BACK_BUTTON: "Webhooks",
        EVENT_TITLE: "Events:",
        STATUS_TITLE: "Status Code:",
        DATE_TITLE: "Date:",
        REQUEST_TITLE: "Request Body",
        RESPONSE_TITLE: "Response Body",
      },
      CREATE: {
        TITLE: "Create Webhook",
        EDIT_TITLE: "Edit Webhook",
        URL_LABEL: "URL*",
        DESCRIPTION_LABEL: "Description*",
        CUSTOM_HEADERS_LABEL: "Custom Headers",
        HEADERS_INFO:
          "Input custom headers in the format of key:value pairs, separated by |, if applicable.",
        CUSTOM_HEADERS_PLACEHOLDER: "Enter Custom Header",
        EVENT_TYPE_LABEL: "Event Types",
        EVENT_PLACEHOLDER: "Select Event Types",
        EVENTS_VALIDATION: "Please select at least one event",
        REQUSET_PAYLOAD_LABEL: "Example Request Payload",
        URL_PLACEHOLDER: "Enter URL",
        URL_REQUIRED: "URL is required",
        URL_MAX_LENGTH: "URL must be less than 255 characters",
        CUSTOM_HEADERS_LENGTH:
          "Custom Headers must be less than 255 characters",
        DESCRIPTION_REQUIRED: "Description is required",
        DESCRIPTION_PLACEHOLDER: "Enter Description",
        DESCRIPTION_MAX_LENGTH: "Description must be less than 255 characters",
        CANCEL_BUTTON: "Cancel",
        SUBMIT_BUTTON: "Save",
      },
      DELETE: {
        TITLE: "Delete Webhook",
        HEADING: "Are you sure you want to Delete this Webhook?",
        DESCRIPTION: "This Webhook will be deleted from your Webhooks list.",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
      STATUS: {
        ENABLE_TITLE: "Enable Webhook",
        DISABLE_TITLE: "Disable Webhook",
        ENABLE_HEADING: "Are you sure you want to enable this Webhook?",
        DISABLE_HEADING: "Are you sure you want to disable this Webhook?",
        ENABLE_DESCRIPTION:
          "This Webhook will be enabled from your Webhooks list.",
        DISABLE_DESCRIPTION:
          "This Webhook will be disabled from your Webhooks list.",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
      DETAILS: {
        TITLE: "Webhook Details",
        BACK_BUTTON: "Webhooks",
        URL_LABEL: "URL: ",
        DESCRIPTION_LABEL: "Description: ",
        STATUS_LABEL: "Status: ",
        SECRET_KEY_LABEL: "Secret Key: ",
        CUSTOM_HEADERS_LABEL: "Custom Headers: ",
        CREATED_DATE_LABEL: "Created Date: ",
        LAST_USED_DATE_LABEL: "Last Used Date: ",
        EDIT_BTN_TEXT: "Edit",
        DELETE_BTN_TEXT: "Delete",
        ENABLE_LABEL: "Enable",
        DISABLE_LABEL: "Disable",
      },
    },
    CUSTOM_FIELDS: {
      TITLE: "Custom Fields",
      NO_CUSTOM_FIELDS: "No Custom fields to show",
      CREATE_CUSTOM_FIELD: "Create Custom Field",
      RESPONSE_ALREADY_EXIST: "Record already exists in system",
      FIELD_EXISTS: "This field already exists",
      CREATE: "Create",
      NAME: "Name",
      PLACEHOLDER: "Type to create a custom field.",
      DELETE: {
        DELETE_CUSTOM_FIELDS: "Delete Custom Field",
        ARE_YOU_SURE: "Are you sure you want to Delete this Custom Field?",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
      EDIT: {
        TITLE: "Edit Custom Field",
        NAME: "Name",
      },
      CUSTOM_FIELD_NAME_REQUIRED: "Custom Field name is required",
      CUSTOM_FIELD_NAME_LIMIT:
        "Custom Field name should be less than 25 letters",
    },
    CANCEL_BUTTON: "Cancel",
    SUBMIT_BUTTON: "Save",
  },
  DASHBOARD: {
    TITLE: "Welcome to",
    HEADING: "Open Letter Connect!",
    SUB_HEADING:
      "Revolutionize your outreach with our personalized mailing solutions. Open Letter Connect goes beyond traditional mail services, offering innovative, seamless ways to connect with your audience. Experience the future of direct mail, where efficiency meets creativity",
    UPCOMING_MAILS_LABEL: "Upcoming Mailings",
    NO_UPCOMING_MAILS: "No Upcoming Mailings",
    NO_CHART_DATA: "No Historical Mailings",
  },
  ADMIN_DASHBOARD: {
    OVERVIEW: {},
    USERS: {
      SEARCH_PLACE_HOLDER:
        "Search by user ID, company name, user name or email",
      CLEAR_FILTER_BTN_TEXT: "Clear Filters",
      NO_RESULT_TEXT: "No results with current filters.",
      NO_USERS_SCREEN: {
        TITLE: "You have no Users in your database",
      },
      ADVANCE_FILTERS: {
        HEADING: "Filters",
        COMPANY_LABEL: "Company",
        COMPANY_PLACEHOLDER: "Select Company",
        NO_COMPANY: "No Company to show",
        ROLE_LABEL: "Role",
        ROLE_PLACEHOLDER: "Select Role",
        NO_ROLE: "No Role to show",
        PLAN_LABEL: "Plan",
        PLAN_PLACEHOLDER: "Select Plan",
        NO_PLAN: "No Plan to show",
        SIGN_UP_DATE_LABEL: "Sign Up Date",
        DATE_PLACEHOLDER: "Select Date",
        SYSTEM_ADMIN_LABEL: "Show System Admins Only",
        CANCEL_BUTTON: "Clear",
        SUBMIT_BUTTON: "Apply",
      },
      IMPERSONATION: {
        HEADER: {
          TITLE: "You are logged in as ",
          SUB_HEADING: "Switch back to Admin",
        },
        IMPERSONATE_MODAL: {
          HEADING: "Are you sure you want to impersonate",
          CANCEL_BUTTON: "No",
          SUBMIT_BUTTON: "Yes",
        },
        SWITCH_BACK_MODAL: {
          HEADING: "Are you sure you want to switch back to the Admin Portal?",
          CANCEL_BUTTON: "No",
          SUBMIT_BUTTON: "Yes",
        },
      },
      USER_ACTION: {
        ENABLE_TITLE: "Enable User",
        DISABLE_TITLE: "Disable User",
        ENABLE_HEADING: "Are you sure you want to enable this User?",
        DISABLE_HEADING: "Are you sure you want to disable this User?",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
      EXPORT: {
        EXPORT_BTN_TEXT: "Export",
        SINGLE_EXPORT_HEADER: "Export",
        MULTI_EXPORT_HEADER: "Export list",
        EXPORT_USERS: "Are you sure you want to export these Users?",
        EXPORT_SINGLE_USERS: "Are you sure you want to export this User?",
        EXPORT_MESSAGE: `You'll receive a confirmation email when the export is completed.`,
      },
      SELECT_ALL_ITEMS: "Select All",
      SELECT_VISIBLE_ITEMS: "Select Visible",
      SELECT_NONE: "Select None",
      USER_ACTIONS: {
        IMPERSONATE: "Impersonate",
        ADD_FUNDS: "Add Funds",
        REMOVE_FUNDS: "Remove Funds",
        VIEW_TRANSACTIONS: "View Transactions",
        ENABLE_ACCOUNT: "Enable Account",
        DISABLE_ACCOUNT: "Disable Account",
      },
    },
    ORDERS: {
      CLEAR_FILTER_BTN_TEXT: "Clear Filters",
      NO_RESULT_TEXT: "No results with current filters.",
      ADVANCE_FILTERS: {
        HEADING: "Filters",
        CREATED_BY_LABEL: "Created By",
        CREATED_BY_PLACEHOLDER: "Search by user name/email",
        NO_CREATED_BY: "No Created By to show",
        PRODUCT_TYPE_LABEL: "Product type",
        PRODUCT_TYPE_PLACEHOLDER: "Select Product Type",
        NO_PRODUCT_TYPE: "No Product Type to show",
        SOURCE_LABEL: "Source",
        SOURCE_PLACEHOLDER: "Select Source",
        NO_SOURCE: "No Source to show",
        ORDER_STATUS_LABEL: "Order Status",
        ORDER_STATUS_PLACEHOLDER: "Select Order Status",
        NO_ORDER_STATUS: "No Order Status to show",
        PAYMENT_STATUS_LABEL: "Payment Status",
        PAYMENT_STATUS_PLACEHOLDER: "Select Payment Status",
        NO_PAYMENT_STATUS: "No Payment Status to show",
        CREATED_DATE_LABEL: "Created Date",
        SCHEDULED_DATE_LABEL: "Scheduled Date",
        DATE_PLACEHOLDER: "Select Date",
        CANCEL_BUTTON: "Clear",
        SUBMIT_BUTTON: "Apply",
      },
      CANCEL_ORDER_MODAL: {
        HEADING: "Confirm Cancel Order?",
        TITLE: "Are you sure you want to cancel this Order?",
        CANCEL_BUTTON: "Go Back",
        SUBMIT_BUTTON: "Cancel Order",
      },
      DISPATCH_ACTION: {
        TITLE: "Dispatch Order",
        HEADING: "Are you sure you want to dispatch this order now?",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
    },
    BATCHES: {
      SEARCH_PLACE_HOLDER: "Search by batch ID",
      CLEAR_FILTER_BTN_TEXT: "Clear Filters",
      NO_RESULT_TEXT: "No results with current filters.",
      NO_BATCHES_SCREEN: {
        TITLE: "You have no Batches in your database",
      },
      ADVANCE_FILTERS: {
        HEADING: "Filters",
        ACCUZIP_STATUS_LABEL: "Accuzip Status",
        ACCUZIP_STATUS_PLACEHOLDER: "Select Accuzip Status",
        NO_ACCUZIP_STATUS: "No Accuzip Status to show",
        PRODUCT_TYPE_LABEL: "Product type",
        PRODUCT_TYPE_PLACEHOLDER: "Select Product Type",
        NO_PRODUCT_TYPE: "No Product Type to show",
        SOURCE_LABEL: "Source",
        SOURCE_PLACEHOLDER: "Select Source",
        BATCH_STATUS_LABEL: "Batch Status",
        BATCH_STATUS_PLACEHOLDER: "Select Batch Status",
        NO_BATCH_STATUS: "No Batch Status to show",
        CREATED_DATE_LABEL: "Created Date",
        SCHEDULED_DATE_LABEL: "Scheduled Date",
        DATE_PLACEHOLDER: "Select Date",
        CANCEL_BUTTON: "Clear",
        SUBMIT_BUTTON: "Apply",
      },
      REGENERATE_PDF: {
        TITLE: "PDF Generation",
        HEADING: "Are you sure you want to regenerate PDF for this batch?",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
      DOWNLOAD_CSV: "Download CSV",
      DOWNLOAD_PDF: "Download PDF",
    },
    CATEGORIES: {
      TITLE: "Template Category",
      CREATE_BTN_TEXT: "Create",
      NO_TEMPLATE_CATEGORY: "No Template Category to show",
      NO_CATEGORIES_SCREEN: {
        TITLE: "You have no Categories in your database",
        CREATE_CATEGORY_BUTTON: "Create New Category",
      },
      CREATE: {
        TITLE: "Create Template Category",
        NAME_LABEL: "Name",
        NAME_PLACEHOLDER: "Template Category",
        CATEGORY_NAME_REQUIRED: "Category name is required",
        CATEGORY_NAME_LIMIT: "Category name should be less than 255 letters",
        DESCRIPTION_LABEL: "Description",
        DESCRIPTION_PLACEHOLDER: "Template Category Description",
        CATEGORY_DESC_LIMIT:
          "Category description should be less than 255 letters",
      },
      DELETE: {
        TITLE: "Delete Category",
        HEADING: "Are you sure you want to Delete this Template Category?",
        CANCEL_BUTTON: "No",
        SUBMIT_BUTTON: "Yes",
      },
      EDIT: {
        TITLE: "Edit Template Category",
        NAME_LABEL: "Name",
        NAME_PLACEHOLDER: "Type to update a Template Category",
      },
      ASSIGN_TEMPLATE: {
        TITLE: "Associate Templates",
        TEMPLATES_LABEL: "Templates",
        TEMPLATES_PLACEHOLDER: "Select Templates",
        SUBMIT_BUTTON: "Update",
      },
      CANCEL_BUTTON: "Cancel",
      SUBMIT_BUTTON: "Save",
    },
    QUERIES: {
      TITLE: "Template Designer Queries",
      CREATE_BTN_TEXT: "Create",
      NO_TEMPLATE_QUERY: "No Template Queries to show",
      TEMPLATE_PUBLISHED: "Template published successfully",
      FILE_DOWNLOADED: "File downloaded successfully",
      FILE_DOWNLOAD_FAILED: "Error downloading file",
      URL_NOT_FOUND: "File URL is missing",
      SEARCH_PLACE_HOLDER: "Search by template name, ID or email",
      PUBLISHED_TEMPLATE_ID: "Published Template ID: ",
      PROFESSIONAL_WINDOWED: "Windowed",
      PROFESSIONAL_NON_WINDOWED: "Non-Windowed",
    },
  },
  SNACKBAR: {
    HEADING: "Notifications",
  },
  MODE_REMINDER: {
    TITLE:
      "Your account is currently in Test Mode. Some features may be limited.",
    LINK_MESSAGE: "Mode Switching Guide",
  },
  REQUEST: {
    SUBSCRIPTION_PLAN_DENIED: {
      TITLE: "Access Limited",
      SUBHEADING:
        "Bulk Export is a feature reserved for our premium plans. Upgrade your subscription today to enjoy this and other advanced features!",
      MESSAGE: " ",
    },
  },
  INTEGRATIONS: {
    YOUR_INTEGRATIONS_LABLE: "Your Integrations",
    SUGGESTED_INTEGRATIONS_LABLE: "Suggested Integrations",
    CARD: {
      READ_LABEL: "Read Documentation",
      DISABLE_BUTTON_LABEL: "Disable",
      ENABLE_BUTTON_LABEL: "Enable",
    },
    MODAL: {
      ENABLE_LABEL: "Enable",
      DISABLE_LABEL: "Disable",
      ENABLE_HEADING: "Are you sure you want to enable",
      DISABLE_HEADING: "Are you sure you want to disable",
    },
  },
};
