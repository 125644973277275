import React from "react";
import { useSearchParams } from "react-router-dom";

function useQueryParams() {
  const [searchParams] = useSearchParams();

  let allSearchParams = "";

  if (searchParams.toString()) {
    allSearchParams = `?${searchParams.toString()}`;
  }

  return { allSearchParams };
}

export default useQueryParams;
