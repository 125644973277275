import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import {
  fetchDashboardWidgets,
  fetchDashboardChart,
  fetchDashboardSpendings,
} from "~/redux/actions/dashboard-actions";

// Utils
import { dateFormat } from "~/utils/date-format";
import { MESSAGES } from "~/utils/message";
import { balanceFormatter } from "~/utils/helperFunctions";
import {
  dashboardStats,
  currentCardData,
  DASHBOARD_DATE_OPTIONS,
} from "~/utils/constants";

// Componets
import StatsCard from "~/components/Dashboard/StatsCard";
import MultiSelect from "~/components/General/MultiSelect";
import CurrentCard from "./CurrentCard";
import Chart from "./Chart";

// MUI Components
import { Box, Container, Grid, Tooltip, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

// Icons
import { InfoCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState([
    DASHBOARD_DATE_OPTIONS[4].title,
  ]);
  const dashboardSpendings = useSelector(
    (state) => state.dashboardReducers.spendings
  );
  const dashboardWidgets = useSelector(
    (state) => state.dashboardReducers.widgets
  );
  const dashboardChart = useSelector((state) => state.dashboardReducers.chart);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToOrderDetail = (id) => {
    navigate(`/orders/detail/overview/${id}`);
  };

  useEffect(() => {
    dispatch(fetchDashboardWidgets());
    dispatch(fetchDashboardSpendings({duration: selectedDate[0]?.toLowerCase() }));
    dispatch(fetchDashboardChart({ duration: selectedDate[0]?.toLowerCase() }));
  }, [selectedDate]);

  return (
    <>
      <Container
        maxWidth="xxl"
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <Grid container spacing={2}>
          {currentCardData.map((content) => {
            return (
              <CurrentCard
                key={content?.id}
                name={content?.name}
                subTitle={content?.subTitle}
                value={
                  content?.id === 2
                    ? balanceFormatter(dashboardSpendings[content?.value] || 0)
                    : dashboardSpendings[content?.value] || 0
                }
                image={content?.img}
              />
            );
          })}
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Box className="welcomeCard">
              <Typography>
                {MESSAGES.DASHBOARD.TITLE}
                <br className="br" />
                {" "}
                {MESSAGES.DASHBOARD.HEADING}
              </Typography>
              <Typography>{MESSAGES.DASHBOARD.SUB_HEADING}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container className="mailingGridWrapper">
          <Grid
            item
            lg={3}
            md={12}
            sx={{
              display: { lg: "block", md: "none", sm: "none", xs: "none" },
            }}
          >
            <Grid container spacing={2}>
              {dashboardStats.map((content) => {
                return (
                  <StatsCard
                    key={content?.id}
                    title={content?.title}
                    image={content?.image}
                    value={dashboardWidgets[content?.value] || 0}
                    percentage={dashboardWidgets[content?.percentage] || 0}
                  />
                );
              })}
              <Grid item lg={12} md={6} className="mailingGrid">
                <Box
                  className="upcomingWrapper"
                  sx={{
                    padding: dashboardWidgets?.upcomingMailings
                      ? "12px 12px 0px 12px"
                      : "12px 12px 30px 12px",
                  }}
                >
                  <Typography>
                    {MESSAGES.DASHBOARD.UPCOMING_MAILS_LABEL}
                    <span>
                      {" "}
                      <Tooltip title="Orders that have been scheduled over the next 7 days.">
                        <img src={InfoCIcon} alt="info" />
                      </Tooltip>
                    </span>
                  </Typography>
                  <Box
                    className="upcomingContentWrapper"
                    sx={{
                      maxHeight:
                        dashboardWidgets?.upcomingMailings &&
                        dashboardWidgets?.upcomingMailings?.length
                          ? "213px"
                          : "155px",
                      overflowY:
                        dashboardWidgets?.upcomingMailings &&
                        dashboardWidgets?.upcomingMailings.length > 2
                          ? "scroll"
                          : "hidden",
                    }}
                  >
                    {dashboardWidgets?.upcomingMailings?.map((upcoming, id) => {
                      return (
                        <Grid
                          key={id}
                          container
                          className="mailContent"
                          onClick={() => {
                            navigateToOrderDetail(upcoming?.orderid);
                          }}
                        >
                          <Grid item lg={7} className="typeIDWrapper">
                            <Typography>{`${upcoming?.quantity} ${upcoming?.productname}`}</Typography>
                            <Typography>ID: {upcoming?.orderid}</Typography>
                          </Grid>
                          <Grid item lg={4} className="priceDateWrapper">
                            <Typography>
                              {balanceFormatter(upcoming?.ordercost || 0)}
                            </Typography>
                            <Typography>
                              {dateFormat(upcoming?.deliverydate, true)}
                            </Typography>
                          </Grid>
                          <Grid item lg={1}>
                            <ArrowForwardIos />
                          </Grid>
                        </Grid>
                      );
                    })}
                    {!dashboardWidgets?.upcomingMailings?.length && (
                      <Box className="noMailPlaceholder">
                        {MESSAGES.DASHBOARD.NO_UPCOMING_MAILS}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={9}
            md={12}
            marginTop="24px"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              position: "relative",
            }}
          >
            <Box
              className={
                dashboardChart?.labels?.length
                  ? "dateDropDown mgRight"
                  : "dateDropDown"
              }
            >
              <MultiSelect
                options={DASHBOARD_DATE_OPTIONS}
                selectedValue={selectedDate}
                setSelectedValue={setSelectedDate}
                simpleSelect={true}
                multiple={false}
                placeholder="Select Date"
                component="overviewdateDropDown"
              />
            </Box>
            <Chart data={dashboardChart} />
          </Grid>
        </Grid>
        {/* Rendering on Tabs */}
        <Grid
          container
          className="mailingGridWrapper"
          rowSpacing={3}
          sx={{
            display: { lg: "none", md: "block" },
          }}
        >
          <Grid
            item
            sx={{
              display: { lg: "none" },
            }}
            md={7}
            sm={12}
          >
            <Grid container spacing={2}>
              {dashboardStats.map((content) => {
                return (
                  <StatsCard
                    key={content?.id}
                    title={content?.title}
                    image={content?.image}
                    value={dashboardWidgets[content?.value]}
                    percentage={dashboardWidgets[content?.percentage]}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid
            item
            md={5}
            sm={12}
            xs={12}
            className="mailingGrid"
            sx={{
              display: { lg: "none", md: "block" },
            }}
          >
            <Box
              className="upcomingWrapper"
              sx={{
                padding: dashboardWidgets?.upcomingMailings
                  ? "12px 12px 0px 12px"
                  : "12px 12px 30px 12px",
              }}
            >
              <Typography>
                {MESSAGES.DASHBOARD.UPCOMING_MAILS_LABEL}
                <span>
                  {" "}
                  <Tooltip title="Orders that have been scheduled over the next 7 days.">
                    <img src={InfoCIcon} alt="info" />
                  </Tooltip>
                </span>
              </Typography>
              <Box
                className="upcomingContentWrapper"
                sx={{
                  maxHeight:
                    dashboardWidgets?.upcomingMailings &&
                    dashboardWidgets?.upcomingMailings?.length
                      ? "213px"
                      : "155px",
                  overflowY:
                    dashboardWidgets?.upcomingMailings &&
                    dashboardWidgets?.upcomingMailings.length > 2
                      ? "scroll"
                      : "hidden",
                  "@media (width <= 1200px)":{
                      maxHeight:
                    dashboardWidgets?.upcomingMailings &&
                    dashboardWidgets?.upcomingMailings?.length
                      ? "180px"
                      : "175px",
                    }
                }}
              >
                {dashboardWidgets?.upcomingMailings?.map((upcoming, id) => {
                  return (
                    <Grid key={id} container className="mailContent">
                      <Grid item lg={7} md={7} sm={7} xs={7} className="typeIDWrapper">
                        <Typography>{`${upcoming?.quantity} ${upcoming?.productname}`}</Typography>
                        <Typography>ID: {upcoming?.orderid}</Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4} className="priceDateWrapper">
                        <Typography>{balanceFormatter(upcoming?.ordercost || 0)}</Typography>
                        <Typography>
                          {dateFormat(upcoming?.deliverydate, true)}
                        </Typography>
                      </Grid>
                      <Grid item lg={1} md={1} sm={1} xs={1}>
                        <ArrowForwardIos />
                      </Grid>
                    </Grid>
                  );
                })}
                {!dashboardWidgets?.upcomingMailings?.length && (
                  <Box className="noMailPlaceholder">
                    {MESSAGES.DASHBOARD.NO_UPCOMING_MAILS}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
