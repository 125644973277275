import React, { useState } from "react";

// Hooks
import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  fetchContacts,
  updateContactTags,
} from "~/redux/actions/contacts-actions";
import { failure, success } from "~/redux/actions/snackbar-actions";

//Utils
import { MESSAGES } from "~/utils/message";

// Components
import MultiSelect from "~/components/General/MultiSelect";
import CreateTagModal from "~/components/Settings/Tags/CreateTag/index.jsx";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Chip, CircularProgress } from "@mui/material";

// Icons
import { ModalCrossCIcon, PlusIconCIcon } from "~/assets/images";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TagsUpdate = (props) => {
  const {
    handleClose,
    open,
    header,
    selectedRows,
    setSelectedRows,
    type,
    selectedTags,
    setSelectedTags,
    search,
    applyFilters,
    filters,
    pagination,
  } = props;

  const [loading, setLoading] = useState(false);
  const [createTagModal, setCreateTagModal] = useState(false);

  const dispatch = useDispatch();

  const tags = useSelector((state) => state.contactsReducers.labels);

  const options = tags
    .filter((tag) => tag.id !== -1)
    .map((tag) => ({ id: tag.id, title: tag.title }));

  if (type === "update") {
    options.push({
      id: "-1",
      title: "Create New Tag",
      custom: true,
      icon: PlusIconCIcon,
      func: () => setCreateTagModal(true),
    });
  }

  const handleTagsSelection = (value) => {
    setSelectedTags(value);
  };

  const getContacts = () => {
    let payload = {
      pagination,
      search: search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    dispatch(fetchContacts(payload));
  };

  const handleUpdateTags = async () => {
    setLoading(true);
    const tagsToUpdate =
      tags
        .filter((item) => selectedTags.find((tag) => item.title === tag))
        .map((item) => item.id)
        .join(",") || "";
    const selectedContacts = selectedRows.map((row) => Number(row)).join(",");
    const payload = {
      contactIds: selectedContacts,
      tagIds: tagsToUpdate,
      action: type === "update" ? "add" : "remove",
      search: search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    const updateTags = await dispatch(updateContactTags(payload));
    if (updateTags.status === 200) {
      getContacts();
      dispatch(success(MESSAGES.CONTACTS.TAGS_UPDATE_SUCCESS_MESSAGE));
      setSelectedRows([]);
      setSelectedTags([]);
      handleClose();
      setLoading(false);
    } else {
      dispatch(
        failure(
          updateTags?.data?.message && MESSAGES.CONTACTS.TAGS_UPDATE_ERROR
        )
      );
      setLoading(false);
    }
  };

  const removeTag = (tag) => {
    setSelectedTags((selectedTags) =>
      selectedTags.filter((item) => {
        return item !== tag;
      })
    );
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="tagsUpdateModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="tagsUpdateModalContent" dividers>
          <Box className="tagsUpdateModalHeader">
            <Typography gutterBottom variant="h4">
              {header}
            </Typography>
          </Box>
          <Box className="tagsUpdateSelect">
            <MultiSelect
              component={"tag-create"}
              options={options}
              selectedValue={selectedTags}
              setSelectedValue={handleTagsSelection}
              placeHolderText={type === "update" ? MESSAGES.CONTACTS.TAGS_ADD_PLACEHOLDER : MESSAGES.CONTACTS.TAGS_REMOVE_PLACEHOLDER}
              noOptionText={MESSAGES.CONTACTS.ADVANCE_FILTERS.NO_SEARCH_TAG}
            />
            <Box className="selectedTagsWrapper">
              {selectedTags.length > 0 && (
                <Box className="selectedTagsWrapper">
                  {selectedTags.map((selectedTag) => {
                    return (
                      <Box className="tagContent" key={selectedTag}>
                        <Chip className="tagChip" label={selectedTag} />
                        <HighlightOffIcon
                          onClick={() => removeTag(selectedTag)}
                        />
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Box>
          <Box className="tagsUpdateModalBtns">
            <Button onClick={handleClose}>
              {MESSAGES.CONTACTS.DELETE.CANCEL_BUTTON}
            </Button>
            <Button onClick={handleUpdateTags} disabled={loading}>
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                MESSAGES.CONTACTS.TAGS_UPDATE_BTN_TEXT
              )}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
      <CreateTagModal
        openModal={createTagModal}
        handleCloseModal={() => setCreateTagModal(false)}
        setSelectedTags={setSelectedTags}
        setTags={true}
        tagsClass={true}
      />
    </>
  );
};

export default TagsUpdate;
