import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { failure, success } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// Components
import Loader from "../../../General/Loader";

// MUI Components
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Icons
import CloseIcon from "../../../../assets/images/modal/modal-cross";
import FileIcon from "../../../../assets/images/admin/file.svg";
import SaveAlt from "@mui/icons-material/SaveAlt";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const QueryDetails = (props) => {
  const { open, onClose, data, loading } = props;
  const [isDownloading, setIsDownloading] = useState({
    status: false,
    id: null,
  });
  const dispatch = useDispatch();

  const getFileExtension = (filename) => filename.split(".").pop();

  const downloadFile = async (fileUrl) => {
    if (!fileUrl) {
      dispatch(failure(MESSAGES.ADMIN_DASHBOARD.QUERIES.URL_NOT_FOUND));
      return;
    }

    try {
      setIsDownloading({
        status: true,
        id: fileUrl,
      });
      const response = await fetch(fileUrl, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob(); // Get the file as a blob
      const blobUrl = window.URL.createObjectURL(blob); // Create a URL for the blob
      const link = document.createElement("a");

      link.href = blobUrl;
      link.download = fileUrl.split("/").pop(); // Extract file name from URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Cleanup the blob URL
      window.URL.revokeObjectURL(blobUrl);
      dispatch(success(MESSAGES.ADMIN_DASHBOARD.QUERIES.FILE_DOWNLOADED));
    } catch (error) {
      dispatch(failure(MESSAGES.ADMIN_DASHBOARD.QUERIES.FILE_DOWNLOAD_FAILED));
    } finally {
      setIsDownloading({
        status: false,
        id: null,
      });
    }
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="queryDetailsWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        className="closeModalButton"
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="queryDetailsWrapper" dividers>
        {loading ? (
          <Box className="loaderWrapper">
            <Loader />
          </Box>
        ) : (
          <>
            <Box className="queryHeader">
              <Typography gutterBottom variant="h4">
                {data?.meta?.templateTitle}
              </Typography>
            </Box>
            <Box className="queryDetails">
              <Box className="infoRow">
                <Typography>Amount</Typography>
                <Typography>${data?.amount ? data?.amount : "-"}</Typography>
              </Box>
              <Box className="infoRow">
                <Typography>URL</Typography>
                {data?.url ? (
                  <Typography
                    className="urlLink"
                    onClick={() => window.open(data?.url, "_blank")}
                  >
                    {data?.url}
                  </Typography>
                ) : (
                  <Typography>-</Typography>
                )}
              </Box>
              <Box className="commentsDiv">
                <Typography>Comments</Typography>
                <Box className="commentsWrapper">
                  <Box className="commentBox">
                    <Typography className="content">
                      {data?.comments ? data?.comments : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="filesDiv">
                <Typography>Files</Typography>
                {data?.files?.length > 0 ? (
                  <Box className="filesWrapper">
                    {data?.files.map((file, index) => {
                      return (
                        <Box className="fileName" key={index}>
                          <Box className="fileBox">
                            <img src={FileIcon} alt="file" />
                            <Typography>{getFileExtension(file)}</Typography>
                          </Box>
                          <Typography className="name" key={file?.id}>
                            {file.split("/").pop()}
                          </Typography>
                          <Box className="downloadIconWrapper">
                          {isDownloading.status && isDownloading.id === file ? (
                            <CircularProgress
                              sx={{
                                color: "#ed5c2f",
                                width: "20px !important",
                                height: "20px !important",
                              }}
                            />
                          ) : (
                            <SaveAlt
                              onClick={() => downloadFile(file)}
                            />
                          )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <>
                    <Typography className="noFileUploaded">
                      No files uploaded
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default QueryDetails;
