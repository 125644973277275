import React, { useState, useEffect } from "react";

// Hooks
import { useSelector } from "react-redux";

// utils
import { MESSAGES } from "~/utils/message.js";
import { balanceFormatter } from "~/utils/helperFunctions.js";
import { dateFormat } from "~/utils/date-format";
import { TRANSACTION_STATUS } from "~/utils/constants";

// Components
import Loader from "~/components/General/Loader";

//MUI Component
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Stack, Tooltip, Pagination, PaginationItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Icons
import { ModalCrossCIcon, NoContactCIcon } from "~/assets/images";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TransactionsModal = (props) => {
  const { handleClose, open, updatePagination, transactionsData } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const loading =
    useSelector((state) => state.adminUsersReducers.userWalletHistoryLoader) ||
    false;

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: 10,
    });
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 200,
      sortable: false,
      flex: screenWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Typography>{dateFormat(params.row.createdAt, true)}</Typography>
      ),
    },
    {
      field: "transactionId",
      headerName: "Transaction ID",
      width: 200,
      sortable: false,
      flex: screenWidth >= 1350 ? 1 : 0,
      renderCell: (params) => <Typography>{params.row.id}</Typography>,
    },
    {
      field: "description",
      headerName: "Description",
      type: "number",
      width: 450,
      sortable: false,
      flex: screenWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="descriptionWrapper">
          <Tooltip title={params.row.remarks}>
            <Typography
              sx={{
                textAlign: "left !important",
              }}
            >
              {params.row.remarks}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      height: 550,
      sortable: false,
      flex: screenWidth >= 1350 ? 1 : 0,
      renderCell: (params) => {
        const balance = balanceFormatter(params.row.amount);
        return (
          <Typography>
            <span className="icon-color">
              {TRANSACTION_STATUS[params.row.type]}
            </span>{" "}
            {balance}
          </Typography>
        );
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 200,
      sortable: false,
      flex: screenWidth >= 1350 ? 1 : 0,
      renderCell: (params) => {
        const balance = balanceFormatter(params.row.meta.newBalance);
        return <Typography>{balance}</Typography>;
      },
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="transactionsModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="transactionsModalContent">
          <Box className="fundsHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.ACCOUNT.WALLET.STRIPE.TRANSACTIONS}
            </Typography>
          </Box>
          <div
            style={{
              marginTop: "25px",
              width: "100%",
            }}
            className="fundsTableWrapper"
          >
            <DataGrid
              rows={transactionsData?.rows || []}
              columns={columns}
              pageSizeOptions={[20, 50, 100]}
              paginationMode="server"
              hideFooterSelectedRowCount
              page={transactionsData?.currentPage || 1}
              loading={loading}
              rowCount={transactionsData?.count}
              hideFooterPagination
              rowSelection={false}
              getRowId={(row) => row.id}
              disableColumnMenu={true}
              className="fundsTableGrid transactionsTableGrid"
              slots={{
                noRowsOverlay: () => (
                  transactionsData?.totalRecordsInDB === 0 && (
                    <Stack className="noRowsTextContacts">
                      <img src={NoContactCIcon} alt="NoContact" />
                      {MESSAGES.ACCOUNT.WALLET.STRIPE.NO_TRANSACTION}
                    </Stack>
                  )
                ),
                noResultsOverlay: () => (
                  <Stack className="noRowsTextContacts">
                    <img src={NoContactCIcon} alt="NoContact" />
                    {MESSAGES.NO_ROWS_TEXT}
                  </Stack>
                ),
                loadingOverlay: Loader,
              }}
            />
            <Box className="fundsPaginationWrapper">
              <Pagination
                count={transactionsData?.lastPage || 1}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
                page={transactionsData?.currentPage || 1}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: ArrowBackIosNewIcon,
                      next: ArrowForwardIosIcon,
                    }}
                    {...item}
                  />
                )}
              />
              {transactionsData && (
                <Typography>
                  Showing {transactionsData?.from} to {transactionsData?.to} of{" "}
                  {transactionsData?.total} results
                </Typography>
              )}
            </Box>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default TransactionsModal;
