import React, { useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import { disableAutoFunding, enableAutoFunding } from "~/redux/actions/stripe-actions";
import { getUserData } from "~/redux/actions/auth-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Components
import AddFunds from "~/components/Account/Wallet/AddFunds";
import ConfirmDialog from "~/components/Account/Wallet/ConfirmDialog/ConfirmDialog";
import EnableFunding from "~/components/Account/Wallet/EnableFunding";

// Utils
import { balanceFormatter } from "~/utils/helperFunctions";
import { MESSAGES } from "~/utils/message";
import { ROLES } from "~/utils/constants";

// MUI Components
import {
  Box,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
} from "@mui/material";

// Icons
import { BalanceCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BalanceCard = () => {
  const dispatch = useDispatch();
  const [fundsModal, setFundsModal] = useState(false);
  const [disableFundingModal, setDisableFundingModal] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    loading: false,
    type: "",
  });
  const user = useSelector((state) => state.userReducers?.user);
  const paymentMethods = useSelector((state) => state.stripeReducers.methods);
  const handleCloseDialog = () => {
    setDialog({ open: false, message: "", loading: false, type: "" });
  };

  const isUser = !(user?.role?.slug === ROLES["ORGANIZATION_ADMIN"]);

  const handleDisable = async () => {
    setDialog((prev) => ({ ...prev, loading: true }));
    const data = await disableAutoFunding();
    if (data.status === 200) {
      dispatch(getUserData());
      dispatch(
        success(
          MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING_DISABLED_SUCCESS,
          MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING
        )
      );
      setDialog({ open: false, message: "", loading: false, type: "" });
    }
    setDialog((prev) => ({ ...prev, loading: false }));
  };

  const handleEnable = async () => {
    setDialog((prev) => ({ ...prev, loading: true }));
    const data = await enableAutoFunding();
    if (data.status === 200) {
      dispatch(getUserData());
      dispatch(
        success(
          MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING_ENABLED_SUCCESS,
          MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING
        )
      );
      setDialog({ open: false, message: "", loading: false, type: "" });
    }
    setDialog((prev) => ({ ...prev, loading: false }));
  };
  return (
    <>
      <Box className="balanceCardWrapper">
        <Box className="balanceImage">
          <img src={BalanceCIcon} alt="balance" />
          <Box className="balanceAmount">
            <Typography>
              {MESSAGES.ACCOUNT.WALLET.STRIPE.YOUR_BALANCE}
            </Typography>
            <Typography>
              {balanceFormatter(user?.organization?.balance)}
            </Typography>
          </Box>
        </Box>
        <Box className="fundsBtn">
          <Tooltip
            title={
              isUser ? MESSAGES.ACCOUNT.WALLET.STRIPE.UNAUTHORIZED_ADD_FUND : ""
            }
          >
            <Button
              onClick={() => {
                if (isUser) {
                  return null;
                }
                if (paymentMethods.length == 0 && !isUser) {
                  dispatch(
                    failure(
                      MESSAGES.ACCOUNT.WALLET.STRIPE.PAYMENT_METHOD_REQUIRED
                    )
                  );
                  return;
                } else {
                  setFundsModal(true);
                }
              }}
              className={isUser ? "disabled" : ""}
            >
              {user?.organization?.balance == undefined ? (
                <CircularProgress
                  sx={{
                    width: "24px !important",
                    height: "24px !important",
                    color: "white",
                  }}
                />
              ) : (
                MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_FUNDS
              )}
            </Button>
          </Tooltip>
          <Typography>
            {MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING}:{" "}
            <span
              onClick={() => {
                if (isUser) return;
                  setDialog({
                    open: true,
                    message: `Are you sure you want to ${
                      user?.organization?.isAutoFundingEnabled
                        ? "disable"
                        : "enable"
                    } auto funding for orders?`,
                    type: user?.organization?.isAutoFundingEnabled
                      ? "disable"
                      : "enable",
                  });
              }}
            >
              {user?.organization?.isAutoFundingEnabled
                ? MESSAGES.ACCOUNT.WALLET.STRIPE.ENABLED
                : MESSAGES.ACCOUNT.WALLET.STRIPE.DISABLED}
            </span>
          </Typography>
          {user?.organization?.isAutoFundingEnabled && (
            <Box className="updateAutoFundingWrapper">
              <Typography className="minAutoFunding">
                {MESSAGES.ACCOUNT.WALLET.STRIPE.MIN_AUTO_FUNDING}{" "}
              <span>
                {balanceFormatter(user?.organization?.minAutoFundingAmount)}
              </span>
              </Typography>
              <span onClick={() => {
                setDisableFundingModal(true);
              }}>
                {MESSAGES.ACCOUNT.WALLET.STRIPE.UPDATE_MIN_AUTO_FUNDING}
              </span>
            </Box>
            )}
        </Box>
        {fundsModal && (
          <AddFunds
            open={fundsModal}
            handleOpen={() => {
              setFundsModal(true);
            }}
            handleClose={() => {
              setFundsModal(false);
            }}
          />
        )}
        {disableFundingModal && (
          <EnableFunding
            open={disableFundingModal}
            handleClose={() => {
              setDisableFundingModal(false);
            }}
          />
        )}
        {dialog.open && (
          <ConfirmDialog
            successButtonName="Yes"
            header={dialog.type === "enable" ? MESSAGES.ACCOUNT.WALLET.STRIPE.ENABLE_AUTO_FUNDING : MESSAGES.ACCOUNT.WALLET.STRIPE.DISABLE_AUTO_FUNDING}
            message={dialog.message}
            open={dialog.open}
            loading={dialog.loading}
            handleClose={handleCloseDialog}
            handleOk={dialog.type === "enable" ? handleEnable : handleDisable}
          />
        )}
      </Box>
    </>
  );
};

export default BalanceCard;
