import React, { useEffect, useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Hooks
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// Actions
import { signInUser } from "~/redux/actions/auth-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Utils
import { EMAIL_REGEX } from "~/utils/constants";
import { MESSAGES } from "~/utils/message";
import { validateForm, realTimeValidation } from "~/utils/formik-validation";

// MUI Components
import {
  Box,
  Grid,
  Input,
  InputLabel,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Container,
} from "@mui/material";

// Icons
import { LoginSCIcon } from "~/assets/images";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Styles
import "../../index.scss";
import useQueryParams from "~/hooks/useQueryParams";

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { allSearchParams } = useQueryParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(EMAIL_REGEX, MESSAGES.AUTH.INVALID_EMAIL)
      .required(MESSAGES.AUTH.EMAIL_REQUIRED),
    password: yup.string().required(MESSAGES.AUTH.PASSWORD_REQUIRED),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
  });

  const signIn = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);

      if (!isValid) {
        return;
      }
      const payload = {
        email: formik.values.email,
        password: formik.values.password.trim(),
      };
      setLoading(true);
      const request = await dispatch(signInUser(payload));
      setLoading(false);
      if (request.message === "success") {
        navigate(`/${allSearchParams}`);
      } else {
        if (
          request?.response?.data?.message ===
          MESSAGES.SIGN_IN.INVALID_EMAIL_OR_PASSWORD
        ) {
          formik.errors["password"] =
            MESSAGES.SIGN_IN.INVALID_EMAIL_OR_PASSWORD;
          formik.touched["password"] = true;
        } else {
          dispatch(
            failure(request?.response?.data?.message || MESSAGES.GENERAL_ERROR)
          );
        }

        if (request?.response?.data?.data?.errors?.length) {
          let serverSideError = request?.response?.data?.data?.errors;
          serverSideError.forEach((error) => {
            formik.errors[error.path] = error.msg;
            formik.touched[error.path] = true;
          });
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    formik.touched.email = false;
    formik.touched.password = false;
    if (searchParams.size > 0) {
      if (searchParams.get("success") === "true") {
        dispatch(success(searchParams.get("message")));
        formik.values.email = searchParams.get("email");
        searchParams.delete("success");
        searchParams.delete("email");
        searchParams.delete("message");
        setSearchParams(searchParams);
      } else if (searchParams.get("success") === "false") {
        dispatch(failure(searchParams.get("message")));
        searchParams.delete("success");
        searchParams.delete("message");
        setSearchParams(searchParams);
      }
    }
  }, []);

  return (
    <Box className="layoutBg" sx={{ minHeight: "102vh", height: "100%" }}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box className="imageWrapper">
              <img src={LoginSCIcon} alt="OpenLetter" />
            </Box>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12} className="formBG">
            <Box className="formWrapper updateForm">
              <Typography variant="h4">{MESSAGES.SIGN_IN.TITLE}</Typography>
              <form className="updateFormWrapper" onSubmit={signIn}>
                <Box className="signinFormContent">
                  <Box className="inputWrapper updateFormInput">
                    <InputLabel className="formLabel">
                      {MESSAGES.SIGN_IN.INPUT_LABEL_EMAIL}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <Input
                      type="text"
                      placeholder={MESSAGES.SIGN_IN.EMAIL_PLACEHOLDER}
                      autoComplete="off"
                      name="email"
                      onChange={formik.handleChange}
                      className={
                        formik.errors.email && formik.touched.email
                          ? "invalid formInput"
                          : "formInput"
                      }
                      onBlur={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.email}
                      </Typography>
                    )}
                  </Box>
                  <Box className="inputWrapper updateFormInput">
                    <InputLabel className="formLabel">
                      {MESSAGES.SIGN_IN.INPUT_LABEL_PASSWORD}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder={MESSAGES.SIGN_IN.INPUT_LABEL_PASSWORD}
                      name="password"
                      onChange={formik.handleChange}
                      className={
                        formik.errors.password && formik.touched.password
                          ? "invalid formInput"
                          : "formInput"
                      }
                      onBlur={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      value={formik.values.password.trim()}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className="passwordIconBtn"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography
                      className="forgotPassword"
                      onClick={() => {
                        navigate("/reset-password");
                      }}
                    >
                      {MESSAGES.SIGN_IN.FORGOT_BUTTON}
                    </Typography>
                    {formik.touched.password && formik.errors.password && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.password}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Button
                  className="signInBtn"
                  type="submit"
                  onClick={() => {
                    validateForm(formik);
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  ) : (
                    MESSAGES.SIGN_IN.SUBMIT_BUTTON
                  )}
                </Button>
              </form>
              <Typography className="account">
                {MESSAGES.SIGN_IN.SIGN_UP_TEXT}
                <span
                  className="signup-btn"
                  onClick={() => {
                    navigate(`/signup${allSearchParams}`);
                  }}
                >
                  {MESSAGES.SIGN_IN.SIGN_UP_BUTTON}
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SignIn;
